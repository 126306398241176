import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Clientele from "../AboutSection/Clientele";
import HireExExperts from "../HireExExpertSection/HireExExperts";
import Testimonial from "../TestimonySection/Testimonial";

const Experience = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1); // Remove leading "/"
    
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Small delay to ensure elements are rendered
  }, [location]);

  return (
    <div id="experience" className="category-page">
      <Clientele id="trusted-by-leading-brands" />
      <HireExExperts id="hire-ex-experts" />
      <Testimonial id="hear-from-our-clients" />
    </div>
  );
};

export default Experience;
