import React from "react";
import "./CustomCard.css";
import { useTranslation } from "react-i18next";

const CustomCard = ({ title, description, image }) => {
  const { t } = useTranslation();

  return (
    <article className="custom-card">
      <div className="icon-container">
        <img src={image} alt={`${title} icon`} className="card-icon" />
      </div>
      <h2 className="ind-title">{t(title)}</h2>
      <div className="card-content">
        <p className="ind-description">{t(description)}</p>
      </div>
    </article>
  );
};

export default CustomCard;
