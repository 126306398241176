import React, { useEffect, useRef, useState } from 'react';

const LazyImage = ({ src, alt,loadByDefault = false, ...props }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(loadByDefault);

  useEffect(() => {
    if (loadByDefault) return; // Skip observer if loading by default
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing after loading
        }
      });
    }, { threshold: 0.1 });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [loadByDefault]);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : undefined} // Load image only when visible
      alt={alt}
      {...props}
    />
  );
};

export default LazyImage;