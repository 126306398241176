import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TechStack from "../TechStackSection/TechStack";
import AreaExpertics from "../ServiceSection/AreaExpertics";

const Skills = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1); // Remove leading "/"
    
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Small delay to ensure elements are rendered
  }, [location]);

  return (
    <div id="skills" className="category-page">
      <TechStack id="techstack" />
      <AreaExpertics id="areaexpertise" />
    </div>
  );
};

export default Skills;
