import React from "react";
import "./TechStack.css";
import {
  TechStackFullStack,
  TechStackFullStackTablet,
  TechStackDataScience,
  TechStackDataScienceTablet,
  TechStackDevOps,
  TechStackDevOpsTablet,
  TechStackFullStackMobile,
  TechStackDevOpsMobile,
  TechStackDataScienceMobile,
  techstackImageUrl
} from "../../../utils/constants";
import scriptLoader from "../../../utils/javascriptsLoader";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const TechStackSprite = ({ className, alt }) => (
  <img className={className} src={techstackImageUrl} alt={alt} />
);

const TechStackSlider = ({ slides, activeCategory }) => (
  <div
    id="techstack_slider"
    className="owl-carousel"
    data-active-category={activeCategory}
  >
    {slides.map((slide, index) => (
      <div key={index} className="slide-container-ts">
        <div className="image-row-ts row-1">
          {slide.row_1_images.map((image, idx) => (
            <div key={idx} className="techstackImageContainer">
              <TechStackSprite className={`${image.className} high_res_image`} alt={image.alt}/>
            </div>
          ))}
        </div>
        <div className="image-row-ts row-2">
          {slide.row_2_images.map((image, idx) => (
            <div key={idx} className="techstackImageContainer">
              <TechStackSprite className={`${image.className} high_res_image`}  alt={image.alt}/>
            </div>
          ))}
        </div>
        <div className="image-row-ts row-3">
          {slide.row_3_images?.map((image, idx) => (
            <div key={idx} className="techstackImageContainer">
              <TechStackSprite className={`${image.className} high_res_image`} alt={image.alt}/>
            </div>
          ))}
        </div>
        {slide.row_4_images && <div className="image-row-ts row-4">
          {slide.row_4_images?.map((image, idx) => (
            <div key={idx} className="techstackImageContainer">
              <TechStackSprite className={`${image.className} high_res_image`} alt={image.alt}/>
            </div>
          ))}
        </div>}
        
      </div>
    ))}
  </div>
);

const TechStackContainer = ({ activeCategory, slides }) => {
  return (
    <div className="techstack-container" key={activeCategory}>
      {activeCategory === "fullstack" && (
        <TechStackSlider
          slides={slides.fullstack}
          activeCategory={activeCategory}
        />
      )}
      {activeCategory === "devops" && (
        <TechStackSlider
          slides={slides.devops}
          activeCategory={activeCategory}
        />
      )}
      {activeCategory === "datascience" && (
        <TechStackSlider
          slides={slides.datascience}
          activeCategory={activeCategory}
        />
      )}
    </div>
  );
};
const TechStack = () => {
  // Set the key based on the current layout
  const [activeCategory, setActiveCategory] = useState("datascience");
  const [loading, setLoading] = useState(true);
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1099 });
  const isBigLaptop = useMediaQuery({ minWidth: 1100 });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const layout = isBigLaptop ? "desktop" : isTablet ? "tablet" : "mobile";
  const { t } = useTranslation();

  const techStack = {
    desktop: {
      datascience: TechStackDataScience,
      fullstack: TechStackFullStack,
      devops: TechStackDevOps,
    },
    tablet: {
      datascience: TechStackDataScienceTablet,
      fullstack: TechStackFullStackTablet,
      devops: TechStackDevOpsTablet,
    },
    mobile: {
      datascience: TechStackDataScienceMobile,
      fullstack: TechStackFullStackMobile,
      devops: TechStackDevOpsMobile,
    },
  };


  useEffect(() => {
    // props.mounted();
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    loadTechStackjs();
    const techStackOwlCarousel = window.$("#techstack_slider").owlCarousel();
    techStackOwlCarousel.trigger("destroy.owl.carousel");
  }, [isTablet, isBigLaptop, activeCategory]);



  const loadTechStackjs = () => {
    const TechStackScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/techstack.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "technologiesId",
      onload: () => setLoading(false),
    };
    scriptLoader(TechStackScript, onScriptLoad);
  };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <section className="container-fluid" id="techstack">
        <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="col-md-12 text-center">
            <Skeleton
              height={40}
              width={`30%`}
              style={{ marginBottom: "1rem" }}
            />{" "}
            <Skeleton
              height={20}
              width={`60%`}
              style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
            />{" "}
          </div>
          <div className="col-md-12 text-center">
            <Skeleton
              height={40}
              width={`30%`}
              style={{ marginBottom: "1rem" }}
            />
            <Skeleton
              height={350}
              width={`70%`}
              style={{ marginTop: "0.5rem" }}
            />{" "}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="container-fluid" key="techstack" id="techstack">
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h2 className={"gradient-text-default"}>{t("techstack.title")}</h2>
            <p className="techstack-desc">{t("techstack.desc")}</p>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className="category-tabs">
          <div className="techstack-tab-row1">
            <div
              className={`techstack-tab ${
                activeCategory === "datascience" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory("datascience");
              }}
            >
              {t("techstack.techstack_title1")}
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "fullstack" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory("fullstack");
              }}
            >
              {t("techstack.techstack_title2")}
            </div>
          </div>
          <div className="techstack-tab-row2">
            <div
              className={`techstack-tab ${
                activeCategory === "devops" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory("devops");
              }}
            >
              {t("techstack.techstack_title3")}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div className="category-tabs">
            <div
              className={`techstack-tab ${
                activeCategory === "datascience" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();                     
                setActiveCategory("datascience");
              }}
            >
              {t("techstack.techstack_title1")}
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "fullstack" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();                     
                setActiveCategory("fullstack");
              }}
            >
              {t("techstack.techstack_title2")}
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "devops" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();                     
                setActiveCategory("devops");
              }}
            >
              {t("techstack.techstack_title3")}
            </div>
          </div>
        </div>
      )}
      <div key={layout}>
        {layout === "desktop" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.desktop}
          />
        ) : layout === "tablet" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.tablet}
          />
        ) : layout === "mobile" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.mobile}
          />
        ) : null}
      </div>
    </section>
  );
};

export default TechStack;
