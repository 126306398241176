import { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./ourculture.css";
import { OurCultureData } from "../../../utils/constants";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (e) => setMatches(e.matches);
    mediaQueryList.addListener(listener);
    return () => mediaQueryList.removeListener(listener);
  }, [query]);

  return matches;
};

const OurCulture = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const slidesData = OurCultureData;

  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);

  const navigate = useCallback(
    (isNext) => {
      setActiveIndex((prevIndex) => {
        const newIndex = isNext
          ? (prevIndex + 1) % slidesData.length
          : prevIndex === 0
          ? slidesData.length - 1
          : prevIndex - 1;
        return newIndex;
      });
    },
    [slidesData.length]
  );

  // Auto Rotation
  const startAutoRotate = useCallback(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        navigate(true);
      }, 6000);
    }
  }, [navigate]);

  const stopAutoRotate = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startAutoRotate();
        } else {
          stopAutoRotate();
        }
      },
      { threshold: 0.5 }
    );

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
      stopAutoRotate();
    };
  }, [startAutoRotate, stopAutoRotate]);

  const getVisibleSlides = () => {
    const totalSlides = slidesData.length;
    const visibleSlides = [];

    if (isMobile) {
      visibleSlides.push({
        slide: slidesData[activeIndex],
        position: "center",
        index: activeIndex,
      });
    } else {
      for (let i = -2; i <= 2; i++) {
        const index = (activeIndex + i + totalSlides) % totalSlides;
        let position = "center";

        if (i === -2) position = "farLeft";
        if (i === -1) position = "left";
        if (i === 1) position = "right";
        if (i === 2) position = "farRight";

        visibleSlides.push({
          slide: slidesData[index],
          position,
          index,
        });
      }
    }

    return visibleSlides;
  };

  // Mouse event handlers for dragging functionality
  const handleMouseDown = (e) => {
    e.preventDefault();
    isDragging.current = true;
    startX.current = e.clientX;
    stopAutoRotate();
    sliderRef.current.querySelector(".slider").style.cursor = "pointer";
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();

    const distance = e.clientX - startX.current;
    if (distance > 50) {
      navigate(false);
      isDragging.current = false;
    } else if (distance < -50) {
      navigate(true);
      isDragging.current = false;
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    startAutoRotate();
    sliderRef.current.querySelector(".slider").style.cursor = "pointer";
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
    isDragging.current = true;
    stopAutoRotate();
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    const distance = e.touches[0].clientX - startX.current;
    if (distance > 50) {
      navigate(false);
      isDragging.current = false;
    } else if (distance < -50) {
      navigate(true);
      isDragging.current = false;
    }
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
    startAutoRotate();
  };

  return (
    <section
      className="bg-light"
      id="ourculture"
      ref={sliderRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
    >
      <div className="container-fluid text-center">
        <div className="col-md-12">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">
              {t("ourculture.maintitle")}
            </h2>
          </div>
        </div>

        <div className="slide-info">
          <p className="slide-desc">
            {t(slidesData[activeIndex].descp)}
            <span className="highlight">
              {t(slidesData[activeIndex].highlight)}
            </span>
            {t(slidesData[activeIndex].descs)}
          </p>
        </div>

        <div
          className="slider-container"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseEnter={stopAutoRotate}
          onMouseLeave={startAutoRotate}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="slider">
            {getVisibleSlides().map(({ slide, position, index }) => {
              const filters = {
                center: "brightness(1) blur(0px) opacity(1)", // no dissolve effect
                left: "brightness(0.5) blur(2px) opacity(0.9)", // subtle dissolve
                right: "brightness(0.5) blur(2px) opacity(0.9)", // subtle dissolve
                farLeft: "brightness(0.4) blur(5px) opacity(0.8)", // more dissolved
                farRight: "brightness(0.4) blur(5px) opacity(0.8)", // more dissolved
              };

              const getTransform = () => {
                switch (position) {
                  case "farLeft":
                    return "translate3d(-100%, -50%, 3rem) scale(0.8)";
                  case "left":
                    return "translate3d(-80%, -50%, 6rem) scale(0.9)";
                  case "center":
                    return "translate3d(-50%, -50%, 9rem)";
                  case "right":
                    return "translate3d(-20%, -50%, 6rem) scale(0.9)";
                  case "farRight":
                    return "translate3d(0%, -50%, 3rem) scale(0.8)";
                  default:
                    return "";
                }
              };

              return (
                <div
                  className={`slide ${position}`}
                  key={index}
                  style={{
                    transform: isMobile
                      ? "translate3d(-50%, -50%, 9rem)"
                      : getTransform(),
                    filter: filters[position],
                    zIndex: position === "center" ? 5 : 1,
                  }}
                >
                  <div className="slide-content">
                    <img
                      className="slide-img"
                      src={slide.image}
                      alt=""
                      
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="dots-navigation">
          {slidesData.map((_, index) => (
            <span
              key={`dot-${index}`}
              className={`dot ${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurCulture;
