import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  HireExExpertsCompany,
  HireExExpertsCompanyMobile,
  HireExExpertsCompanyTablet,
  hireExpertsImageUrl,
} from "../../../utils/constants";
import "./hireexexperts.css";
import scriptLoader from "../../../utils/javascriptsLoader";
import Skeleton from "react-loading-skeleton";

const HireSprite = ({ className,alt }) => <img className={className} src={hireExpertsImageUrl} alt={alt}/>;

const HireExExpertsSlider = ({ slides }) => (
  <div id="hireexexperts_slider" className="owl-carousel">
    {slides.map((slide, index) => (
      <div key={index} className="slide-container-he">
        <div className="image-row-he row-1">
          {slide.row_1_images.map((image, idx) => (
            <div key={idx} className="Hireximagecontainer">
              <HireSprite className={`${image.className} high_res_image`} alt={image.alt} />
            </div>
          ))}
        </div>
        <div className="image-row-he row-2">
          {slide.row_2_images.map((image, idx) => (
            <div key={idx} className="Hireximagecontainer">
              <HireSprite className={`${image.className} high_res_image`} alt={image.alt} />
            </div>
          ))}
        </div>
        <div className="image-row-he row-3">
          {slide.row_3_images.map((image, idx) => (
            <div key={idx} className="Hireximagecontainer">
              <HireSprite className={`${image.className} high_res_image`} alt={image.alt}/>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const HireExExperts = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  // Define breakpoints
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });

  // Set the key based on the current layout
  const layout = isDesktop ? "desktop" : isTablet ? "tablet" : "mobile";

  useEffect(() => {
    // props.mounted();
    loadHireExpertsJs();
    const owlCarousel = window.$("#hireexexperts_slider").owlCarousel();
    owlCarousel.trigger("destroy.owl.carousel");
  }, [layout]);

  const loadHireExpertsJs = () => {
    const hireExExpertsScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/hireexexperts.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "technologiesId",
    };
    scriptLoader(hireExExpertsScript, onScriptLoad);
  };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <section className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <Skeleton height={40} width={`30%`} />
          </div>
        </div>
        <div className="container" style={{ margin: "20px auto" }}>
          <div className="skeleton-container">
            <Skeleton height={250} />
          </div>
        </div>
        <div className="col-md-12 text-center">
          <Skeleton height={15} width={`20%`} />
        </div>
      </section>
    );
  }

  return (
    <React.Fragment>
      <section className="container-fluid" id="hire-ex-experts">
        <div className="row">
          <div
            className="col-md-12 text-center wow fadeIn"
            // style={{ marginBottom: "20px" }}
          >
            <div className="title d-inline-block">
              <h2 className={"gradient-text-default"}>
                {t("hireexexperts.title")}
              </h2>
            </div>
          </div>
        </div>
        <div className="hireexexperts-container" key={layout}>
          {layout === "desktop" && (
            <HireExExpertsSlider slides={HireExExpertsCompany} />
          )}
          {layout === "tablet" && (
            <HireExExpertsSlider slides={HireExExpertsCompanyTablet} />
          )}
          {layout === "mobile" && (
            <HireExExpertsSlider slides={HireExExpertsCompanyMobile} />
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default HireExExperts;
