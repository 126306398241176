export const DEFAULT_TABS = [
  // {
  //     id: 1,
  //     title: 'Home',
  //     path: '#home',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 2,
  //     title: 'About Us',
  //     path: '#about',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 3,
  //     title: 'Services',
  //     path: '#services',
  //     div_id: 's_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'service_dropdown_toggle',
  //     subTabs: [
  //         {title: 'Services We Offer', path: '#services', partialParentPath: '/'},
  //         {title: 'Industries We Work In', path: '#industries', partialParentPath: '/'},
  //         {title: ' Technologies We Use', path: '#technologies', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 4,
  //     title: 'Products',
  //     path: '#products',
  //     div_id: 'p_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'product_dropdown_toggle',
  //     subTabs: [
  //         {title: 'H+Tree', path: '#p_hplus_tree', partialParentPath: '/'},
  //         {title: 'F(x) Data Cloud', path: '#p_fx_data_clouds', partialParentPath: '/'},
  //         {title: 'F(x) Optimizer', path: '#p_fx_optimizer', partialParentPath: '/'},
  //         {title: 'F(x) ERP', path: '#p_fx_erp', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 5,
  //     title: 'Contact Us',
  //     path: '#contact',
  //     partialParentPath: '/'
  // },
];

export const PHASE_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "rejected",
  4: "completed",
};

export const TIMELINE_TAIL_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "pending",
  4: "completed",
};

export const USER_PROGRESS_STATUS_CLASSES = {
  1: "secondary",
  2: "success",
  3: "danger",
  4: "light",
};

export const MAX_FILE_UPLOAD_SIZE = 50;

export const MAX_PAGINATION_ITEMS = 5;

export const DEVELOPER_PROCESS_STATUS = [
  { name: "In Process", value: 2 },
  { name: "Rejected", value: 3 },
  { name: "Completed", value: 4 },
];

export const OurCultureData = [
  {
    descp: "ourculture.descp1",
    highlight: "ourculture.highlight1",
    descs: "ourculture.descs1",
    title: "ourculture.title1",
    image: "../images/ourculture/GlobalCulture.webp",
  },
  {
    descp: "ourculture.descp2",
    highlight: "ourculture.highlight2",
    descs: "ourculture.descs2",
    title: "ourculture.title2",
    image: "../images/ourculture/WokeCulture.webp",
  },
  {
    descp: "ourculture.descp3",
    highlight: "ourculture.highlight3",
    descs: "ourculture.descs3",
    title: "ourculture.title3",
    image: "../images/ourculture/RadicalCondor.webp",
  },
  {
    descp: "ourculture.descp4",
    highlight: "ourculture.highlight4",
    descs: "ourculture.descs4",
    title: "ourculture.title4",
    image: "../images/ourculture/FriendlyWorkCulture.webp",
  },
  {
    descp: "ourculture.descp5",
    highlight: "ourculture.highlight5",
    descs: "ourculture.descs5",
    title: "ourculture.title5",
    image: "../images/ourculture/HighPerformance.webp",
  },
  {
    descp: "ourculture.descp6",
    highlight: "ourculture.highlight6",
    descs: "ourculture.descs6",
    title: "ourculture.title6",
    image: "../images/ourculture/HardWorkingTeam.webp",
  },
]

export const hireExpertsImageUrl = "/images/hireexexperts/hire_sprite-image.webp"

export const HireExExpertsCompany = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Amazon" },
      { className: "bg-2", alt: "Johnson & Johnson" },
      { className: "bg-3", alt: "Accenture" },
      { className: "bg-4", alt: "Nokia" },
      { className: "bg-5", alt: "Bitcoin.com" },
      { className: "bg-6", alt: "Jio" },
    ],

    row_2_images: [
      { className: "bg-7", alt: "TCS" },
      { className: "bg-8", alt: "Adani Ports and Logistics" },
      { className: "bg-9", alt: "Crisil" },
      { className: "bg-10", alt: "RCI-DRDO" },
      { className: "bg-12", alt: "Easel" },
      { className: "bg-11", alt: "Zidisha" },
    ],

    row_3_images: [
      { className: "bg-15", alt: "Silver Touch Technologies" },
      { className: "bg-13", alt: "Braided Data Solutions" },
      { className: "bg-14", alt: "Tatvasoft" },
      { className: "bg-17", alt: "Crest (data)" },
      { className: "bg-18", alt: "Brilworks" },
      { className: "bg-16", alt: "Tatvic" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-19", alt: "Amitech" },
      { className: "bg-20", alt: "Neurance AI Technologies" },
      { className: "bg-24", alt: "SPS Digital Solutions" },
      { className: "bg-21", alt: "Tristate Technology" },
      { className: "bg-23", alt: "RE" },
      { className: "bg-22", alt: "Cruxbot" },
    ],

    row_2_images: [
      { className: "bg-25", alt: "Maxgen" },
      { className: "bg-26", alt: "Orionswave" },
      { className: "bg-27", alt: "Saubhagyam" },
      { className: "bg-28", alt: "Spectrics Solutions" },
      { className: "bg-29", alt: "Team Elementals" },
      { className: "bg-30", alt: "SparksToIdeas" },
    ],

    row_3_images: [
      { className: "bg-32", alt: "Keshri Edutech" },
      { className: "bg-35", alt: "Brainy Beam" },
      { className: "bg-33", alt: "Netsavvies" },
      { className: "bg-34", alt: "Intrinsic Science Labs" },
      { className: "bg-31", alt: "Rao" },
      { className: "bg-36", alt: "Swizzdigi" },
    ],
  },
  {
    id: 2, // Fixed duplicate ID
    row_1_images: [
      { className: "bg-37", alt: "Avestan Technologies" },
      { className: "bg-38", alt: "Applied S & I" },
      { className: "bg-39", alt: "Amici Infotech" },
      { className: "bg-40", alt: "Clear Kinetic" },
      { className: "bg-42", alt: "Cloudsleet Technologies" },
      { className: "bg-41", alt: "Technext Studio" },
    ],

    row_2_images: [],
    row_3_images: [],
  },
];

export const HireExExpertsCompanyTablet = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Amazon" },
      { className: "bg-2", alt: "Johnson & Johnson" },
      { className: "bg-3", alt: "Accenture" },
      { className: "bg-4", alt: "Nokia" },
    ],

    row_2_images: [
      { className: "bg-5", alt: "Bitcoin.com" },
      { className: "bg-6", alt: "Jio" },
      { className: "bg-7", alt: "TCS" },
      { className: "bg-8", alt: "Adani Ports and Logistics" },
    ],

    row_3_images: [
      { className: "bg-9", alt: "Crisil" },
      { className: "bg-10", alt: "RCI-DRDO" },
      { className: "bg-12", alt: "Easel" },
      { className: "bg-11", alt: "Zidisha" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-15", alt: "Silver Touch Technologies" },
      { className: "bg-13", alt: "Braided Data Solutions" },
      { className: "bg-14", alt: "Tatvasoft" },
      { className: "bg-17", alt: "Crest (data)" },
    ],

    row_2_images: [
      { className: "bg-18", alt: "Brilworks" },
      { className: "bg-16", alt: "Tatvic" },
      { className: "bg-19", alt: "Amitech" },
      { className: "bg-20", alt: "Neurance AI Technologies" },
    ],

    row_3_images: [
      { className: "bg-24", alt: "SPS Digital Solutions" },
      { className: "bg-21", alt: "Tristate Technology" },
      { className: "bg-23", alt: "RE" },
      { className: "bg-22", alt: "Cruxbot" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-25", alt: "Maxgen" },
      { className: "bg-26", alt: "Orionswave" },
      { className: "bg-27", alt: "Saubhagyam" },
      { className: "bg-28", alt: "Spectrics Solutions" },
    ],

    row_2_images: [
      { className: "bg-29", alt: "Team Elementals" },
      { className: "bg-30", alt: "SparksToIdeas" },
      { className: "bg-32", alt: "Keshri Edutech" },
      { className: "bg-35", alt: "Brainy Beam" },
    ],

    row_3_images: [
      { className: "bg-33", alt: "Netsavvies" },
      { className: "bg-34", alt: "Intrinsic Science Labs" },
      { className: "bg-31", alt: "Rao" },
      { className: "bg-36", alt: "Swizzdigi" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-37", alt: "Avestan Technologies" },
      { className: "bg-38", alt: "Applied S & I" },
      { className: "bg-39", alt: "Amici Infotech" },
      { className: "bg-40", alt: "Clear Kinetic" },
    ],

    row_2_images: [
      { className: "bg-42", alt: "Cloudsleet Technologies" },
      { className: "bg-41", alt: "Technext Studio" },
    ],

    row_3_images: [],
  },
];

export const HireExExpertsCompanyMobile = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Amazon" },
      { className: "bg-2", alt: "Johnson & Johnson" },
      { className: "bg-3", alt: "Accenture" },
    ],

    row_2_images: [
      { className: "bg-4", alt: "Nokia" },
      { className: "bg-5", alt: "Bitcoin.com" },
      { className: "bg-6", alt: "Jio" },
    ],

    row_3_images: [
      { className: "bg-7", alt: "TCS" },
      { className: "bg-8", alt: "Adani Ports and Logistics" },
      { className: "bg-9", alt: "Crisil" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-10", alt: "RCI-DRDO" },
      { className: "bg-12", alt: "Easel" },
      { className: "bg-11", alt: "Zidisha" },
    ],

    row_2_images: [
      { className: "bg-15", alt: "Silver Touch Technologies" },
      { className: "bg-13", alt: "Braided Data Solutions" },
      { className: "bg-14", alt: "Tatvasoft" },
    ],

    row_3_images: [
      { className: "bg-17", alt: "Crest (data)" },
      { className: "bg-18", alt: "Brilworks" },
      { className: "bg-16", alt: "Tatvic" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-19", alt: "Amitech" },
      { className: "bg-20", alt: "Neurance AI Technologies" },
      { className: "bg-24", alt: "SPS Digital Solutions" },
    ],

    row_2_images: [
      { className: "bg-21", alt: "Tristate Technology" },
      { className: "bg-23", alt: "RE" },
      { className: "bg-22", alt: "Cruxbot" },
    ],

    row_3_images: [
      { className: "bg-25", alt: "Maxgen" },
      { className: "bg-26", alt: "Orionswave" },
      { className: "bg-27", alt: "Saubhagyam" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-28", alt: "Spectrics Solutions" },
      { className: "bg-29", alt: "Team Elementals" },
      { className: "bg-30", alt: "SparksToIdeas" },
    ],

    row_2_images: [
      { className: "bg-32", alt: "Keshri Edutech" },
      { className: "bg-35", alt: "Brainy Beam" },
      { className: "bg-33", alt: "Netsavvies" },
    ],

    row_3_images: [
      { className: "bg-34", alt: "Intrinsic Science Labs" },
      { className: "bg-31", alt: "Rao" },
      { className: "bg-36", alt: "Swizzdigi" },
    ],
  },
  {
    id: 4,
    row_1_images: [
      { className: "bg-37", alt: "Avestan Technologies" },
      { className: "bg-38", alt: "Applied S & I" },
      { className: "bg-39", alt: "Amici Infotech" },
    ],

    row_2_images: [
      { className: "bg-40", alt: "Clear Kinetic" },
      { className: "bg-42", alt: "Cloudsleet Technologies" },
      { className: "bg-41", alt: "Technext Studio" },
    ],

    row_3_images: [],
  },
];

export const techstackImageUrl = "/images/TechStack/techstack-sprite.webp"

export const TechStackFullStack = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-68", alt: "React" },
      { className: "bg-69", alt: "Angular" },
      { className: "bg-70", alt: "Django" },
      { className: "bg-71", alt: "Next.js" },
      { className: "bg-72", alt: "Flask" },
      { className: "bg-73", alt: "FastAPI" },
      { className: "bg-74", alt: "Laravel" },
      { className: "bg-75", alt: "Express.js" },
    ],
    row_2_images: [
      { className: "bg-76", alt: "WordPress" },
      { className: "bg-77", alt: "ASP.NET" },
      { className: "bg-78", alt: "Flutter" },
      { className: "bg-79", alt: "Spring Boot" },
      { className: "bg-80", alt: "React Native" },
      { className: "bg-81", alt: "Redux" },
      { className: "bg-82", alt: "Axios" },
      { className: "bg-83", alt: "Lodash" },
    ],
    row_3_images: [
      { className: "bg-84", alt: "Bootstrap" },
      { className: "bg-85", alt: "Material UI" },
      { className: "bg-86", alt: "PrimeReact" },
      { className: "bg-87", alt: "PNG" },
      { className: "bg-88", alt: "Tailwind CSS" },
      { className: "bg-89", alt: "JavaScript" },
      { className: "bg-90", alt: "Java" },
      { className: "bg-91", alt: "C#" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-92", alt: "PHP" },
      { className: "bg-93", alt: "HTML5" },
      { className: "bg-94", alt: "CSS3" },
      { className: "bg-95", alt: "C++" },
      { className: "bg-96", alt: "C" },
      { className: "bg-97", alt: "NPM" },
      { className: "bg-98", alt: "Webpack" },
      { className: "bg-99", alt: "Gulp" },
    ],
    row_2_images: [
      { className: "bg-100", alt: "Grunt" },
      { className: "bg-101", alt: "C (another variation)" },
      { className: "bg-102", alt: "MySQL" },
      { className: "bg-103", alt: "MariaDB" },
      { className: "bg-104", alt: "PostgreSQL" },
      { className: "bg-105", alt: "SQLite" },
      { className: "bg-106", alt: "Redis" },
      { className: "bg-107", alt: "MongoDB" },
    ],
    row_3_images: [
      { className: "bg-108", alt: "Socket.io" },
      { className: "bg-109", alt: "Vite" },
      { className: "bg-110", alt: "tRPC" },
      { className: "bg-111", alt: "Stripe" },
      { className: "bg-112", alt: "NestJS" },
      { className: "bg-113", alt: "Prisma" },
      { className: "bg-114", alt: "Neo4j" },
      { className: "bg-115", alt: "NATS" },
    ],
  },
];

export const TechStackFullStackTablet = [
  
    {
      id: 0,
      row_1_images: [
        { className: "bg-68", alt: "React" },
        { className: "bg-69", alt: "Angular" },
        { className: "bg-70", alt: "Django" },
        { className: "bg-71", alt: "Next.js" },
      ],
      row_2_images: [
        { className: "bg-72", alt: "Flask" },
        { className: "bg-73", alt: "FastAPI" },
        { className: "bg-74", alt: "Laravel" },
        { className: "bg-75", alt: "Express.js" },
      ],
      row_3_images: [
        { className: "bg-76", alt: "WordPress" },
        { className: "bg-77", alt: "ASP.NET" },
        { className: "bg-78", alt: "Flutter" },
        { className: "bg-79", alt: "Spring Boot" },
      ],
      row_4_images: [
        { className: "bg-80", alt: "React Native" },
        { className: "bg-81", alt: "Redux" },
        { className: "bg-82", alt: "Axios" },
        { className: "bg-83", alt: "Lodash" },
      ],
    },
    {
      id: 1,
      row_1_images: [
        { className: "bg-84", alt: "Bootstrap" },
        { className: "bg-85", alt: "Material UI" },
        { className: "bg-86", alt: "PrimeReact" },
        { className: "bg-87", alt: "PNG" },
      ],
      row_2_images: [
        { className: "bg-88", alt: "Tailwind CSS" },
        { className: "bg-89", alt: "JavaScript" },
        { className: "bg-90", alt: "Java" },
        { className: "bg-91", alt: "C#" },
      ],
      row_3_images: [
        { className: "bg-92", alt: "PHP" },
        { className: "bg-93", alt: "HTML5" },
        { className: "bg-94", alt: "CSS3" },
        { className: "bg-95", alt: "C++" },
      ],
      row_4_images: [
        { className: "bg-96", alt: "C" },
        { className: "bg-97", alt: "NPM" },
        { className: "bg-98", alt: "Webpack" },
        { className: "bg-99", alt: "Gulp" },
      ],
    },
    {
      id: 2,
      row_1_images: [
        { className: "bg-100", alt: "Grunt" },
        { className: "bg-101", alt: "C (another variation)" },
        { className: "bg-102", alt: "MySQL" },
        { className: "bg-103", alt: "MariaDB" },
      ],
      row_2_images: [
        { className: "bg-104", alt: "PostgreSQL" },
        { className: "bg-105", alt: "SQLite" },
        { className: "bg-106", alt: "Redis" },
        { className: "bg-107", alt: "MongoDB" },
      ],
      row_3_images: [
        { className: "bg-108", alt: "Socket.io" },
        { className: "bg-109", alt: "Vite" },
        { className: "bg-110", alt: "tRPC" },
        { className: "bg-111", alt: "Stripe" },
      ],
      row_4_images: [
        { className: "bg-112", alt: "NestJS" },
        { className: "bg-113", alt: "Prisma" },
        { className: "bg-114", alt: "Neo4j" },
        { className: "bg-115", alt: "NATS" },
      ],
    },
];

export const TechStackFullStackMobile = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-68", alt: "React" },
      { className: "bg-69", alt: "Angular" },
      { className: "bg-70", alt: "Django" },
    ],
    row_2_images: [
      { className: "bg-71", alt: "Next.js" },
      { className: "bg-72", alt: "Flask" },
      { className: "bg-73", alt: "FastAPI" },
    ],
    row_3_images: [
      { className: "bg-74", alt: "Laravel" },
      { className: "bg-75", alt: "Express.js" },
      { className: "bg-76", alt: "WordPress" },
    ],
    row_4_images: [
      { className: "bg-77", alt: "ASP.NET" },
      { className: "bg-78", alt: "Flutter" },
      { className: "bg-79", alt: "Spring Boot" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-80", alt: "React Native" },
      { className: "bg-81", alt: "Redux" },
      { className: "bg-82", alt: "Axios" },
    ],
    row_2_images: [
      { className: "bg-83", alt: "Lodash" },
      { className: "bg-84", alt: "Bootstrap" },
      { className: "bg-85", alt: "Material UI" },
    ],
    row_3_images: [
      { className: "bg-86", alt: "PrimeReact" },
      { className: "bg-87", alt: "PNG" },
      { className: "bg-88", alt: "Tailwind CSS" },
    ],
    row_4_images: [
      { className: "bg-89", alt: "JavaScript" },
      { className: "bg-90", alt: "Java" },
      { className: "bg-91", alt: "C#" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-92", alt: "PHP" },
      { className: "bg-93", alt: "HTML5" },
      { className: "bg-94", alt: "CSS3" },
    ],
    row_2_images: [
      { className: "bg-95", alt: "C++" },
      { className: "bg-96", alt: "C" },
      { className: "bg-97", alt: "NPM" },
    ],
    row_3_images: [
      { className: "bg-98", alt: "Webpack" },
      { className: "bg-99", alt: "Gulp" },
      { className: "bg-100", alt: "Grunt" },
    ],
    row_4_images: [
      { className: "bg-101", alt: "C (another variation)" },
      { className: "bg-102", alt: "MySQL" },
      { className: "bg-103", alt: "MariaDB" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-104", alt: "PostgreSQL" },
      { className: "bg-105", alt: "SQLite" },
      { className: "bg-106", alt: "Redis" },
    ],
    row_2_images: [
      { className: "bg-107", alt: "MongoDB" },
      { className: "bg-108", alt: "Socket.io" },
      { className: "bg-109", alt: "Vite" },
    ],
    row_3_images: [
      { className: "bg-110", alt: "tRPC" },
      { className: "bg-111", alt: "Stripe" },
      { className: "bg-112", alt: "NestJS" },
    ],
    row_4_images: [
      { className: "bg-113", alt: "Prisma" },
      { className: "bg-114", alt: "Neo4j" },
      { className: "bg-115", alt: "NATS" },
    ],
  },
];

export const TechStackDataScience = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "LangChain" },
      { className: "bg-2", alt: "LlamaIndex" },
      { className: "bg-3", alt: "Haystack" },
      { className: "bg-4", alt: "Autogen" },
      { className: "bg-5", alt: "Haystack (different version)" },
      { className: "bg-6", alt: "Lit-GPT" },
      { className: "bg-7", alt: "MLflow" },
      { className: "bg-8", alt: "Hugging Face Transformers" },
    ],
    row_2_images: [
      { className: "bg-9", alt: "Ragas" },
      { className: "bg-10", alt: "PyTorch" },
      { className: "bg-11", alt: "TensorFlow" },
      { className: "bg-12", alt: "Keras" },
      { className: "bg-13", alt: "Scikit-learn" },
      { className: "bg-14", alt: "XGBoost" },
      { className: "bg-15", alt: "Hugging Face Transformers" },
      { className: "bg-16", alt: "Fast.ai" },
    ],
    row_3_images: [
      { className: "bg-17", alt: "OpenAI" },
      { className: "bg-18", alt: "AI (Anthropic Claude)" },
      { className: "bg-19", alt: "Amazon Bedrock" },
      { className: "bg-20", alt: "Gemini" },
      { className: "bg-21", alt: "AI21 Labs" },
      { className: "bg-22", alt: "Haystack (diff version)" },
      { className: "bg-23", alt: "Hugging Face Transformers" },
      { className: "bg-24", alt: "LLaMA" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-25", alt: "Mistral AI" },
      { className: "bg-26", alt: "H2O.ai" },
      { className: "bg-27", alt: "Gemma" },
      { className: "bg-28", alt: "PNG" },
      { className: "bg-29", alt: "Grok" },
      { className: "bg-30", alt: "Mamba" },
      { className: "bg-31", alt: "PNG" },
      { className: "bg-32", alt: "PNG" },
    ],
    row_2_images: [
      { className: "bg-33", alt: "DALL·E" },
      { className: "bg-34", alt: "PNG" },
      { className: "bg-35", alt: "NLTK" },
      { className: "bg-36", alt: "spaCy" },
      { className: "bg-37", alt: "BERT" },
      { className: "bg-38", alt: "Word2Vec" },
      { className: "bg-39", alt: "PNG" },
      { className: "bg-40", alt: "PNG" },
    ],
    row_3_images: [
      { className: "bg-41", alt: "PNG" },
      { className: "bg-42", alt: "ImageAI" },
      { className: "bg-43", alt: "dlib" },
      { className: "bg-44", alt: "PNG" },
      { className: "bg-45", alt: "PNG" },
      { className: "bg-46", alt: "RLLib" },
      { className: "bg-47", alt: "Pandas" },
      { className: "bg-48", alt: "NumPy" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-49", alt: "Matplotlib" },
      { className: "bg-50", alt: "Seaborn" },
      { className: "bg-51", alt: "Ibis" },
      { className: "bg-52", alt: "Apache Hadoop" },
      { className: "bg-53", alt: "Apache Spark" },
      { className: "bg-54", alt: "Databricks" },
      { className: "bg-55", alt: "Hadoop HDFS" },
      { className: "bg-56", alt: "Python" },
    ],
    row_2_images: [
      { className: "bg-57", alt: "R" },
      { className: "bg-58", alt: "Metaflow" },
      { className: "bg-59", alt: "Yellowbrick" },
      { className: "bg-60", alt: "Kubeflow" },
      { className: "bg-61", alt: "Qdrant" },
      { className: "bg-62", alt: "Scikit-learn" },
      { className: "bg-63", alt: "Weaviate" },
      { className: "bg-64", alt: "Milvus" },
    ],
    row_3_images: [
      { className: "bg-65", alt: "PNG" },
      { className: "bg-66", alt: "Lightning AI" },
      { className: "bg-67", alt: "Faiss" },
    ],
  },
];

export const TechStackDataScienceTablet = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "LangChain" },
      { className: "bg-2", alt: "LlamaIndex" },
      { className: "bg-3", alt: "Haystack" },
      { className: "bg-4", alt: "Autogen" },
    ],
    row_2_images: [
      { className: "bg-5", alt: "Haystack (different version)" },
      { className: "bg-6", alt: "Lit-GPT" },
      { className: "bg-7", alt: "MLflow" },
      { className: "bg-8", alt: "Hugging Face Transformers" },
    ],
    row_3_images: [
      { className: "bg-9", alt: "Ragas" },
      { className: "bg-10", alt: "PyTorch" },
      { className: "bg-11", alt: "TensorFlow" },
      { className: "bg-12", alt: "Keras" },
    ],
    row_4_images: [
      { className: "bg-13", alt: "Scikit-learn" },
      { className: "bg-14", alt: "XGBoost" },
      { className: "bg-15", alt: "Hugging Face Transformers" },
      { className: "bg-16", alt: "Fast.ai" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-17", alt: "OpenAI" },
      { className: "bg-18", alt: "AI (Anthropic Claude)" },
      { className: "bg-19", alt: "Amazon Bedrock" },
      { className: "bg-20", alt: "Gemini" },
    ],
    row_2_images: [
      { className: "bg-21", alt: "AI21 Labs" },
      { className: "bg-22", alt: "Haystack (diff version)" },
      { className: "bg-23", alt: "Hugging Face Transformers" },
      { className: "bg-24", alt: "LLaMA" },
    ],
    row_3_images: [
      { className: "bg-25", alt: "Mistral AI" },
      { className: "bg-26", alt: "H2O.ai" },
      { className: "bg-27", alt: "Gemma" },
      { className: "bg-28", alt: "PNG" },
    ],
    row_4_images: [
      { className: "bg-29", alt: "Grok" },
      { className: "bg-30", alt: "Mamba" },
      { className: "bg-31", alt: "PNG" },
      { className: "bg-32", alt: "PNG" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-33", alt: "DALL·E" },
      { className: "bg-34", alt: "PNG" },
      { className: "bg-35", alt: "NLTK" },
      { className: "bg-36", alt: "spaCy" },
    ],
    row_2_images: [
      { className: "bg-37", alt: "BERT" },
      { className: "bg-38", alt: "Word2Vec" },
      { className: "bg-39", alt: "PNG" },
      { className: "bg-40", alt: "PNG" },
    ],
    row_3_images: [
      { className: "bg-41", alt: "PNG" },
      { className: "bg-42", alt: "ImageAI" },
      { className: "bg-43", alt: "dlib" },
      { className: "bg-44", alt: "PNG" },
    ],
    row_4_images: [
      { className: "bg-45", alt: "PNG" },
      { className: "bg-46", alt: "RLLib" },
      { className: "bg-47", alt: "Pandas" },
      { className: "bg-48", alt: "NumPy" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-49", alt: "Matplotlib" },
      { className: "bg-50", alt: "Seaborn" },
      { className: "bg-51", alt: "Ibis" },
      { className: "bg-52", alt: "Apache Hadoop" },
    ],
    row_2_images: [
      { className: "bg-53", alt: "Apache Spark" },
      { className: "bg-54", alt: "Databricks" },
      { className: "bg-55", alt: "Hadoop HDFS" },
      { className: "bg-56", alt: "Python" },
    ],
    row_3_images: [
      { className: "bg-57", alt: "R" },
      { className: "bg-58", alt: "Metaflow" },
      { className: "bg-59", alt: "Yellowbrick" },
      { className: "bg-60", alt: "Kubeflow" },
    ],
    row_4_images: [
      { className: "bg-61", alt: "Qdrant" },
      { className: "bg-62", alt: "Scikit-learn" },
      { className: "bg-63", alt: "Weaviate" },
      { className: "bg-64", alt: "Milvus" },
    ],
  },
  {
    id: 4,
    row_1_images: [
      { className: "bg-65", alt: "PNG" },
      { className: "bg-66", alt: "Lightning AI" },
      { className: "bg-67", alt: "Faiss" },
    ],
    row_2_images: [],

    row_3_images: [],
    row_4_images: [],
  },
];

export const TechStackDataScienceMobile = [
    {
      id: 0,
      row_1_images: [
        { className: "bg-1", alt: "LangChain" },
        { className: "bg-2", alt: "LlamaIndex" },
        { className: "bg-3", alt: "Haystack" },
      ],
      row_2_images: [
        { className: "bg-4", alt: "Autogen" },
        { className: "bg-5", alt: "Haystack (different version)" },
        { className: "bg-6", alt: "Lit-GPT" },
      ],
      row_3_images: [
        { className: "bg-7", alt: "MLflow" },
        { className: "bg-8", alt: "Hugging Face Transformers" },
        { className: "bg-9", alt: "Ragas" },
      ],
      row_4_images: [
        { className: "bg-10", alt: "PyTorch" },
        { className: "bg-11", alt: "TensorFlow" },
        { className: "bg-12", alt: "Keras" },
      ],
    },
    {
      id: 1,
      row_1_images: [
        { className: "bg-13", alt: "Scikit-learn" },
        { className: "bg-14", alt: "XGBoost" },
        { className: "bg-15", alt: "Hugging Face Transformers" },
      ],
      row_2_images: [
        { className: "bg-16", alt: "Fast.ai" },
        { className: "bg-17", alt: "OpenAI" },
        { className: "bg-18", alt: "AI (Anthropic Claude)" },
      ],
      row_3_images: [
        { className: "bg-19", alt: "Amazon Bedrock" },
        { className: "bg-20", alt: "Gemini" },
        { className: "bg-21", alt: "AI21 Labs" },
      ],
      row_4_images: [
        { className: "bg-22", alt: "Haystack (diff version)" },
        { className: "bg-23", alt: "Hugging Face Transformers" },
        { className: "bg-24", alt: "LLaMA" },
      ],
    },
    {
      id: 2,
      row_1_images: [
        { className: "bg-25", alt: "Mistral AI" },
        { className: "bg-26", alt: "H2O.ai" },
        { className: "bg-27", alt: "Gemma" },
      ],
      row_2_images: [
        { className: "bg-28", alt: "PNG" },
        { className: "bg-29", alt: "Grok" },
        { className: "bg-30", alt: "Mamba" },
      ],
      row_3_images: [
        { className: "bg-31", alt: "PNG" },
        { className: "bg-32", alt: "PNG" },
        { className: "bg-33", alt: "DALL·E" },
      ],
      row_4_images: [
        { className: "bg-34", alt: "PNG" },
        { className: "bg-35", alt: "NLTK" },
        { className: "bg-36", alt: "spaCy" },
      ],
    },
    {
      id: 3,
      row_1_images: [
        { className: "bg-37", alt: "BERT" },
        { className: "bg-38", alt: "Word2Vec" },
        { className: "bg-39", alt: "PNG" },
      ],
      row_2_images: [
        { className: "bg-40", alt: "PNG" },
        { className: "bg-41", alt: "PNG" },
        { className: "bg-42", alt: "ImageAI" },
      ],
      row_3_images: [
        { className: "bg-43", alt: "dlib" },
        { className: "bg-44", alt: "PNG" },
        { className: "bg-45", alt: "PNG" },
      ],
      row_4_images: [
        { className: "bg-46", alt: "RLLib" },
        { className: "bg-47", alt: "Pandas" },
        { className: "bg-48", alt: "NumPy" },
      ],
    },
    {
      id: 4,
      row_1_images: [
        { className: "bg-49", alt: "Matplotlib" },
        { className: "bg-50", alt: "Seaborn" },
        { className: "bg-51", alt: "Ibis" },
      ],
      row_2_images: [
        { className: "bg-52", alt: "Apache Hadoop" },
        { className: "bg-53", alt: "Apache Spark" },
        { className: "bg-54", alt: "Databricks" },
      ],
      row_3_images: [
        { className: "bg-55", alt: "Hadoop HDFS" },
        { className: "bg-56", alt: "Python" },
        { className: "bg-57", alt: "R" },
      ],
      row_4_images: [
        { className: "bg-58", alt: "Metaflow" },
        { className: "bg-59", alt: "Yellowbrick" },
        { className: "bg-60", alt: "Kubeflow" },
      ],
    },
    {
      id: 5,
      row_1_images: [
        { className: "bg-61", alt: "Qdrant" },
        { className: "bg-62", alt: "Scikit-learn" },
        { className: "bg-63", alt: "Weaviate" },
      ],
      row_2_images: [
        { className: "bg-64", alt: "Milvus" },
        { className: "bg-65", alt: "PNG" },
        { className: "bg-66", alt: "Lightning AI" },
      ],
      row_3_images: [
        { className: "bg-67", alt: "Faiss" },],
    row_4_images: [],
  },
];

export const TechStackDevOps = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-116", alt: "Terraform" },
      { className: "bg-117", alt: "Ansible" },
      { className: "bg-118", alt: "GitHub" },
      { className: "bg-119", alt: "GitLab" },
      { className: "bg-120", alt: "Bitbucket" },
      { className: "bg-121", alt: "AWS (Amazon Web Services)" },
      { className: "bg-122", alt: "Microsoft Azure" },
      { className: "bg-123", alt: "DigitalOcean" },
    ],
    row_2_images: [
      { className: "bg-124", alt: "YAML" },
      { className: "bg-125", alt: "Graylog" },
      { className: "bg-126", alt: "Grafana" },
      { className: "bg-127", alt: "Grafana Loki" },
      { className: "bg-128", alt: "ELK Stack (Elasticsearch, Logstash, Kibana)" },
      { className: "bg-129", alt: "GitHub Actions" },
      { className: "bg-130", alt: "Jenkins" },
      { className: "bg-131", alt: "CircleCI" },
    ],
    row_3_images: [],
  },
];

export const TechStackDevOpsTablet = [
    {
      id: 0,
      row_1_images: [
        { className: "bg-116", alt: "Terraform" },
        { className: "bg-117", alt: "Ansible" },
        { className: "bg-118", alt: "GitHub" },
        { className: "bg-119", alt: "GitLab" },
      ],
      row_2_images: [
        { className: "bg-120", alt: "Bitbucket" },
        { className: "bg-121", alt: "AWS (Amazon Web Services)" },
        { className: "bg-122", alt: "Microsoft Azure" },
        { className: "bg-123", alt: "DigitalOcean" },
      ],
      row_3_images: [
        { className: "bg-124", alt: "YAML" },
        { className: "bg-125", alt: "Graylog" },
        { className: "bg-126", alt: "Grafana" },
        { className: "bg-127", alt: "Grafana Loki" },
      ],
      row_4_images: [
        { className: "bg-128", alt: "ELK Stack (Elasticsearch, Logstash, Kibana)" },
        { className: "bg-129", alt: "GitHub Actions" },
        { className: "bg-130", alt: "Jenkins" },
        { className: "bg-131", alt: "CircleCI" },
      ],
    },
];

export const TechStackDevOpsMobile = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-116", alt: "Terraform" },
      { className: "bg-117", alt: "Ansible" },
      { className: "bg-118", alt: "GitHub" },
    ],
    row_2_images: [
      { className: "bg-119", alt: "GitLab" },
      { className: "bg-120", alt: "Bitbucket" },
      { className: "bg-121", alt: "AWS (Amazon Web Services)" },
    ],
    row_3_images: [
      { className: "bg-122", alt: "Microsoft Azure" },
      { className: "bg-123", alt: "DigitalOcean" },
      { className: "bg-124", alt: "YAML" },
    ],
    row_4_images: [
      { className: "bg-125", alt: "Graylog" },
      { className: "bg-126", alt: "Grafana" },
      { className: "bg-127", alt: "Grafana Loki" },
    ],
  },

  {
    id: 1,
    row_1_images: [
      { className: "bg-128", alt: "ELK Stack (Elasticsearch, Logstash, Kibana)" },
      { className: "bg-129", alt: "GitHub Actions" },
      { className: "bg-130", alt: "Jenkins" },
    ],
    row_2_images: [
      { className: "bg-131", alt: "CircleCI" },
    ],

    row_3_images: [],
    row_4_images: [],
  },
];

export const awardsImageUrl = "/images/awards/awards_sprite.png"

export const Awards = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Clutch GLOBAL SPRING 2024" },
      { className: "bg-2", alt: "Clutch TOP COMPANY Generative AI Company 2024" },
      { className: "bg-3", alt: "Clutch TOP COMPANY Artificial Intelligence Company 2024" },
      { className: "bg-4", alt: "Clutch TOP COMPANY Machine Learning Company 2024" },
      { className: "bg-5", alt: "Clutch TOP COMPANY AI Consulting Company 2024" },
      { className: "bg-6", alt: "Clutch GLOBAL FALL 2023" },
    ],
    row_2_images: [
      { className: "bg-7", alt: "techreviewer.co TOP AngularJS Developers 2021" },
      { className: "bg-8", alt: "BNI" },
      { className: "bg-9", alt: "Clutch TOP COMPANY AI Deployment Company 2024" },
      { className: "bg-10", alt: "Google" },
      { className: "bg-11", alt: "Glassdoor" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-12", alt: "Clutch TOP COMPANY AI Video Generation Company 2024" },
      { className: "bg-13", alt: "Clutch TOP COMPANY AI Code Generation Company 2024" },
      { className: "bg-14", alt: "Clutch TOP COMPANY AI Strategy Company 2024" },
      { className: "bg-15", alt: "Clutch TOP COMPANY AI Speech Generation Company 2024" },
      { className: "bg-16", alt: "Clutch TOP COMPANY AI Text Generation Company 2024" },
      { className: "bg-17", alt: "Clutch TOP COMPANY AI Image Generation Company 2023" },
    ],
    row_2_images: [
      { className: "bg-18", alt: "Clutch TOP COMPANY Recommendation Systems Company 2024" },
      { className: "bg-19", alt: "Clutch TOP COMPANY Computer Vision Company 2024" },
      { className: "bg-20", alt: "Clutch TOP COMPANY Natural Language Processing Company 2023" },
      { className: "bg-21", alt: "Clutch TOP COMPANY Chatbot Company 2023" },
      { className: "bg-22", alt: "Clutch TOP COMPANY NextJS Developer 2023" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-23", alt: "Clutch TOP COMPANY Development 2024 INDIA" },
      { className: "bg-24", alt: "Clutch TOP AI CODE GENERATION COMPANY INDIA 2024" },
      { className: "bg-25", alt: "Clutch TOP AI VIDEO GENERATION COMPANY INDIA 2024" },
      { className: "bg-26", alt: "Clutch TOP ARTIFICIAL INTELLIGENCE COMPANY INDIA 2024" },
      { className: "bg-27", alt: "Clutch TOP AI DEPLOYMENT COMPANY INDIA 2024" },
      { className: "bg-28", alt: "Clutch TOP AI SPEECH GENERATION COMPANY INDIA 2024" },
    ],
    row_2_images: [
      { className: "bg-29", alt: "Clutch TOP AI IMAGE GENERATION COMPANY INDIA 2024" },
      { className: "bg-30", alt: "Clutch TOP AI STRATEGY COMPANY INDIA 2024" },
      { className: "bg-31", alt: "Clutch TOP AI CONSULTING COMPANY INDIA 2024" },
      { className: "bg-32", alt: "Clutch TOP MACHINE LEARNING COMPANY INDIA 2024" },
      { className: "bg-33", alt: "Clutch TOP COMPUTER VISION COMPANY INDIA 2024" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-34", alt: "Top managed service Provider Clutch India 2024" },
      { className: "bg-35", alt: "Top Python and django developer Clutch India 2024" },
      { className: "bg-36", alt: "Top recommendation system company Clutch India 2024" },
      { className: "bg-37", alt: "Top Generative AI company Clutch India 2024" },
      { className: "bg-38", alt: "Top AI strategy company Clutch India 2024" },
      { className: "bg-39", alt: "Top AI consulting company Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-40", alt: "Top AI image generation Clutch India 2024" },
      { className: "bg-41", alt: "Top AI development Clutch India 2024" },
      { className: "bg-42", alt: "Top AI test generation Clutch India 2024" },
      { className: "bg-43", alt: "Top AI video generation Clutch India 2024" },
      { className: "bg-44", alt: "Top AI company Clutch India 2024" },
    ],
  },
  {
    id: 4,
    row_1_images: [
      { className: "bg-45", alt: "Top ML company Clutch India 2024" },
      { className: "bg-46", alt: "Top NextJS company Clutch India 2024" },
      { className: "bg-47", alt: "Top HTML5 company Clutch India 2024" },
      { className: "bg-48", alt: "Top Computer Vision company Clutch India 2024" },
      { className: "bg-49", alt: "Top Staff Augmentation company Clutch India 2024" },
      { className: "bg-50", alt: "Top Recommendation System company Clutch Gaming India" },
    ],
    row_2_images: [
      { className: "bg-51", alt: "Top Managed Service Provider Clutch Gaming India" },
      { className: "bg-52", alt: "Top Software Developer Clutch Gaming India" },
      { className: "bg-53", alt: "Top Software Developer Clutch Gaming India" },
      { className: "bg-54", alt: "Top Web Developers Clutch Gaming India" },
      { className: "bg-55", alt: "Top Software Developer Clutch Gaming India" },
    ],
  },
  {
    id: 5,
    row_1_images: [
      { className: "bg-56", alt: "Top Software Developer Clutch Supply Chain Logistic Company" },
      { className: "bg-57", alt: "Top Software Developer Clutch Gaming Ahmedabad" },
      { className: "bg-58", alt: "Top Web Developer Clutch Gaming Ahmedabad" },
      { className: "bg-59", alt: "Top Web Developer Clutch Manufacturing Ahmedabad" },
    ],
    row_2_images: [],
  },
];

export const AwardsTablet = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Clutch GLOBAL SPRING 2024" },
      { className: "bg-2", alt: "Clutch TOP COMPANY Generative AI Company 2024" },
      { className: "bg-3", alt: "Clutch TOP COMPANY Artificial Intelligence Company 2024" },
      { className: "bg-6", alt: "Clutch GLOBAL FALL 2023" },
    ],
    row_2_images: [
      { className: "bg-5", alt: "Clutch TOP COMPANY AI Consulting Company 2024" },
      { className: "bg-4", alt: "Clutch TOP COMPANY Machine Learning Company 2024" },
      { className: "bg-9", alt: "Clutch TOP COMPANY AI Deployment Company 2024" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-7", alt: "techreviewer.co TOP AngularJS Developers 2021" },
      { className: "bg-8", alt: "BNI" },
      { className: "bg-10", alt: "Google" },
      { className: "bg-11", alt: "Glassdoor" },
    ],
    row_2_images: [
      { className: "bg-12", alt: "Clutch TOP COMPANY AI Video Generation Company 2024" },
      { className: "bg-13", alt: "Clutch TOP COMPANY AI Code Generation Company 2024" },
      { className: "bg-14", alt: "Clutch TOP COMPANY AI Strategy Company 2024" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-15", alt: "Clutch TOP COMPANY AI Speech Generation Company 2024" },
      { className: "bg-16", alt: "Clutch TOP COMPANY AI Text Generation Company 2024" },
      { className: "bg-17", alt: "Clutch TOP COMPANY AI Image Generation Company 2023" },
      { className: "bg-18", alt: "Clutch TOP COMPANY Recommendation Systems Company 2024" },
    ],
    row_2_images: [
      { className: "bg-19", alt: "Clutch TOP COMPANY Computer Vision Company 2024" },
      { className: "bg-20", alt: "Clutch TOP COMPANY Natural Language Processing Company 2023" },
      { className: "bg-21", alt: "Clutch TOP COMPANY Chatbot Company 2023" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-22", alt: "Clutch TOP COMPANY NextJS Developer 2023" },
      { className: "bg-23", alt: "Clutch TOP COMPANY Development 2024 INDIA" },
      { className: "bg-24", alt: "Clutch TOP AI CODE GENERATION COMPANY INDIA 2024" },
      { className: "bg-25", alt: "Clutch TOP AI VIDEO GENERATION COMPANY INDIA 2024" },
    ],
    row_2_images: [
      { className: "bg-26", alt: "Clutch TOP ARTIFICIAL INTELLIGENCE COMPANY INDIA 2024" },
      { className: "bg-27", alt: "Clutch TOP AI DEPLOYMENT COMPANY INDIA 2024" },
      { className: "bg-28", alt: "Clutch TOP AI SPEECH GENERATION COMPANY INDIA 2024" },
    ],
  },
  {
    id: 4,
    row_1_images: [
      { className: "bg-29", alt: "Clutch TOP AI IMAGE GENERATION COMPANY INDIA 2024" },
      { className: "bg-30", alt: "Clutch TOP AI STRATEGY COMPANY INDIA 2024" },
      { className: "bg-31", alt: "Clutch TOP AI CONSULTING COMPANY INDIA 2024" },
      { className: "bg-32", alt: "Clutch TOP MACHINE LEARNING COMPANY INDIA 2024" },
    ],
    row_2_images: [
      { className: "bg-33", alt: "Clutch TOP COMPUTER VISION COMPANY INDIA 2024" },
      { className: "bg-34", alt: "Top managed service Provider Clutch India 2024" },
      { className: "bg-35", alt: "Top Python and django developer Clutch India 2024" },
    ],
  },
  {
    id: 5,
    row_1_images: [
      { className: "bg-36", alt: "Top recommendation system company Clutch India 2024" },
      { className: "bg-37", alt: "Top Generative AI company Clutch India 2024" },
      { className: "bg-38", alt: "Top AI strategy company Clutch India 2024" },
      { className: "bg-39", alt: "Top AI consulting company Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-40", alt: "Top AI image generation Clutch India 2024" },
      { className: "bg-41", alt: "Top AI development Clutch India 2024" },
      { className: "bg-42", alt: "Top AI test generation Clutch India 2024" },
    ],
  },
  {
    id: 6,
    row_1_images: [
      { className: "bg-43", alt: "Top AI video generation Clutch India 2024" },
      { className: "bg-44", alt: "Top AI company Clutch India 2024" },
      { className: "bg-45", alt: "Top ML company Clutch India 2024" },
      { className: "bg-46", alt: "Top NextJS company Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-47", alt: "Top HTML5 company Clutch India 2024" },
      { className: "bg-48", alt: "Top Computer Vision company Clutch India 2024" },
      { className: "bg-49", alt: "Top Staff Augmentation company Clutch India 2024" },
    ],
  },
  {
    id: 7,
    row_1_images: [
      { className: "bg-50", alt: "Top Recommendation System company Clutch Gaming India" },
      { className: "bg-51", alt: "Top Managed Service Provider Clutch Gaming India" },
      { className: "bg-52", alt: "Top Software Developer Clutch Gaming India" },
      { className: "bg-53", alt: "Top Software Developer Clutch Gaming India" },
    ],
    row_2_images: [
      { className: "bg-54", alt: "Top Web Developers Clutch Gaming India" },
      { className: "bg-55", alt: "Top Software Developer Clutch Gaming India" },
      { className: "bg-56", alt: "Top Software Developer Clutch Supply Chain Logistic Company" },
    ],
  },
  {
    id: 8,
    row_1_images: [
      { className: "bg-57", alt: "Top Software Developer Clutch Gaming Ahmedabad" },
      { className: "bg-58", alt: "Top Web Developer Clutch Gaming Ahmedabad" },
      { className: "bg-59", alt: "Top Web Developer Clutch Manufacturing Ahmedabad" },
    ],
    row_2_images: [],
  },
];

export const AwardsMobile = [
  {
    id: 0,
    row_1_images: [
      { className: "bg-1", alt: "Clutch GLOBAL SPRING 2024" },
      { className: "bg-2", alt: "Clutch TOP COMPANY Generative AI Company 2024" },
      { className: "bg-3", alt: "Clutch TOP COMPANY Artificial Intelligence Company 2024" },
    ],
    row_2_images: [
      { className: "bg-4", alt: "Clutch TOP COMPANY Machine Learning Company 2024" },
      { className: "bg-6", alt: "Clutch GLOBAL FALL 2023" },
    ],
  },
  {
    id: 1,
    row_1_images: [
      { className: "bg-7", alt: "techreviewer.co TOP AngularJS Developers 2021" },
      { className: "bg-5", alt: "Clutch TOP COMPANY AI Consulting Company 2024" },
      { className: "bg-8", alt: "BNI" },
    ],
    row_2_images: [
      { className: "bg-10", alt: "Google" },
      { className: "bg-11", alt: "Glassdoor" },
    ],
  },
  {
    id: 2,
    row_1_images: [
      { className: "bg-9", alt: "Clutch TOP COMPANY AI Deployment Company 2024" },
      { className: "bg-12", alt: "Clutch TOP COMPANY AI Video Generation Company 2024" },
      { className: "bg-13", alt: "Clutch TOP COMPANY AI Code Generation Company 2024" },
    ],
    row_2_images: [
      { className: "bg-14", alt: "Clutch TOP COMPANY AI Strategy Company 2024" },
      { className: "bg-15", alt: "Clutch TOP COMPANY AI Speech Generation Company 2024" },
    ],
  },
  {
    id: 3,
    row_1_images: [
      { className: "bg-16", alt: "Clutch TOP COMPANY AI Text Generation Company 2024" },
      { className: "bg-17", alt: "Clutch TOP COMPANY AI Image Generation Company 2023" },
      { className: "bg-18", alt: "Clutch TOP COMPANY Recommendation Systems Company 2024" },
    ],
    row_2_images: [
      { className: "bg-19", alt: "Clutch TOP COMPANY Computer Vision Company 2024" },
      { className: "bg-20", alt: "Clutch TOP COMPANY Natural Language Processing Company 2023" },
    ],
  },
  {
    id: 4,
    row_1_images: [
      { className: "bg-21", alt: "Clutch TOP COMPANY Chatbot Company 2023" },
      { className: "bg-22", alt: "Clutch TOP COMPANY NextJS Developer 2023" },
      { className: "bg-23", alt: "Clutch TOP COMPANY Development 2024 INDIA" },
    ],
    row_2_images: [
      { className: "bg-24", alt: "Clutch TOP AI CODE GENERATION COMPANY INDIA 2024" },
      { className: "bg-25", alt: "Clutch TOP AI VIDEO GENERATION COMPANY INDIA 2024" },
    ],
  },
  {
    id: 5,
    row_1_images: [
      { className: "bg-26", alt: "Clutch TOP ARTIFICIAL INTELLIGENCE COMPANY INDIA 2024" },
      { className: "bg-27", alt: "Clutch TOP AI DEPLOYMENT COMPANY INDIA 2024" },
      { className: "bg-28", alt: "Clutch TOP AI SPEECH GENERATION COMPANY INDIA 2024" },
    ],
    row_2_images: [
      { className: "bg-29", alt: "Clutch TOP AI IMAGE GENERATION COMPANY INDIA 2024" },
      { className: "bg-30", alt: "Clutch TOP AI STRATEGY COMPANY INDIA 2024" },
    ],
  },
  {
    id: 6,
    row_1_images: [
      { className: "bg-31", alt: "Clutch TOP AI CONSULTING COMPANY INDIA 2024" },
      { className: "bg-32", alt: "Clutch TOP MACHINE LEARNING COMPANY INDIA 2024" },
      { className: "bg-33", alt: "Clutch TOP COMPUTER VISION COMPANY INDIA 2024" },
    ],
    row_2_images: [
      { className: "bg-34", alt: "Top managed service Provider Clutch India 2024" },
      { className: "bg-35", alt: "Top Python and django developer Clutch India 2024" },
    ],
  },
  {
    id: 7,
    row_1_images: [
      { className: "bg-36", alt: "Top recommendation system company Clutch India 2024" },
      { className: "bg-37", alt: "Top Generative AI company Clutch India 2024" },
      { className: "bg-38", alt: "Top AI strategy company Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-39", alt: "Top AI consulting company Clutch India 2024" },
      { className: "bg-40", alt: "Top AI image generation Clutch India 2024" },
    ],
  },
  {
    id: 8,
    row_1_images: [
      { className: "bg-41", alt: "Top AI development Clutch India 2024" },
      { className: "bg-42", alt: "Top AI test generation Clutch India 2024" },
      { className: "bg-43", alt: "Top AI video generation Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-44", alt: "Top AI company Clutch India 2024" },
      { className: "bg-45", alt: "Top ML company Clutch India 2024" },
    ],
  },
  {
    id: 9,
    row_1_images: [
      { className: "bg-46", alt: "Top NextJS company Clutch India 2024" },
      { className: "bg-47", alt: "Top HTML5 company Clutch India 2024" },
      { className: "bg-48", alt: "Top Computer Vision company Clutch India 2024" },
    ],
    row_2_images: [
      { className: "bg-49", alt: "Top Staff Augmentation company Clutch India 2024" },
      { className: "bg-50", alt: "Top Recommendation System company Clutch Gaming India" },
    ],
  },
  {
    id: 10,
    row_1_images: [
      { className: "bg-51", alt: "Top Managed Service Provider Clutch Gaming India" },
      { className: "bg-52", alt: "Top Software Developer Clutch Gaming India" },
      { className: "bg-53", alt: "Top Software Developer Clutch Gaming India" },
    ],
    row_2_images: [
      { className: "bg-54", alt: "Top Web Developers Clutch Gaming India" },
      { className: "bg-55", alt: "Top Software Developer Clutch Gaming India" },
    ],
  },
  {
    id: 11,
    row_1_images: [
      { className: "bg-56", alt: "Top Software Developer Clutch Supply Chain Logistic Company" },
      { className: "bg-57", alt: "Top Software Developer Clutch Gaming Ahmedabad" },
      { className: "bg-58", alt: "Top Web Developer Clutch Gaming Ahmedabad" },
    ],
    row_2_images: [
      { className: "bg-59", alt: "Top Web Developer Clutch Manufacturing Ahmedabad" },
    ],
  },
];

export const clienteleImageUrl = "/images/clientele/Logos/clientele-sprite-img.png"

export const clienteleData = [
  {
    id: 0,
    row_1_images: [
      { className: 'bg-1', alt: 'Johnson & Johnson' },
      { className: 'bg-2', alt: 'bakeri' },
      { className: 'bg-3', alt: 'symphony' },
      { className: 'bg-4', alt: 'pareit' }
    ],
    row_2_images: [
      { className: 'bg-5', alt: 'Gwrs' },
      { className: 'bg-6', alt: 'up government' },
      { className: 'bg-7', alt: 'gujarat state yog board' },
      { className: 'bg-8', alt: 'Gmr' }
    ]
  },
  {
    id: 1,
    row_1_images: [
      { className: 'bg-9', alt: 'algo' },
      { className: 'bg-10', alt: 'iifl ahmisa run' },
      { className: 'bg-11', alt: 'government of assam' },
      { className: 'bg-12', alt: 'capital numbers' }
    ],
    row_2_images: [
      { className: 'bg-13', alt: 'solaris finance' },
      { className: 'bg-14', alt: 'globhe' },
      { className: 'bg-15', alt: 'super sourcing' },
      { className: 'bg-16', alt: 'yes referral' }
    ]
  },
  {
    id: 2,
    row_1_images: [
      { className: 'bg-17', alt: 'rtudata' },
      { className: 'bg-18', alt: 'braided data solution' },
      { className: 'bg-19', alt: 'paravision' },
      { className: 'bg-20', alt: 'little darlings nursery' }
    ],
    row_2_images: [
      { className: 'bg-21', alt: 'sumtracker' },
      { className: 'bg-22', alt: 'givelify' },
      { className: 'bg-23', alt: 'risk fintech' },
      { className: 'bg-24', alt: 'we team' }
    ]
  },
  {
    id: 3,
    row_1_images: [
      { className: 'bg-25', alt: 'wetales' },
      { className: 'bg-26', alt: 'doqfy' },
      { className: 'bg-27', alt: 'sanskrit cr' },
      { className: 'bg-28', alt: 'secure slice' }
    ],
    row_2_images: [
      { className: 'bg-29', alt: 'neurog' },
      { className: 'bg-30', alt: 'victor' },
      { className: 'bg-31', alt: 'alma systems' },
      { className: 'bg-32', alt: 'maroon migrates' }
    ]
  },
  {
    id: 4,
    row_1_images: [
      { className: 'bg-33', alt: 'valunova' },
      { className: 'bg-34', alt: 'sourcebae' },
      { className: 'bg-35', alt: 'rocketreach' },
      { className: 'bg-36', alt: 'rugg media group' }
    ],
    row_2_images: [
      { className: 'bg-37', alt: 'market place' },
      { className: 'bg-38', alt: 'optimize technologies' },
      { className: 'bg-39', alt: 'raps media outdoor media' },
      { className: 'bg-40', alt: 'mind it systems' }
    ]
  }
];

export const clienteleTabletData = [
  {
    id: 0,
    row_1_images: [
      { className: 'bg-1', alt: 'Johnson & Johnson' },
      { className: 'bg-2', alt: 'Bakeri' },
      { className: 'bg-3', alt: 'Symphony' }
    ],
    row_2_images: [
      { className: 'bg-4', alt: 'Pareit' },
      { className: 'bg-5', alt: 'Gwrs' },
      { className: 'bg-6', alt: 'UP Government' }
    ]
  },
  {
    id: 1,
    row_1_images: [
      { className: 'bg-7', alt: 'Gujarat State Yog Board' },
      { className: 'bg-8', alt: 'Gmr' },
      { className: 'bg-9', alt: 'Algo' }
    ],
    row_2_images: [
      { className: 'bg-10', alt: 'IIFL Ahmisa Run' },
      { className: 'bg-11', alt: 'Government of Assam' },
      { className: 'bg-12', alt: 'Capital Numbers' }
    ]
  },
  {
    id: 2,
    row_1_images: [
      { className: 'bg-13', alt: 'Solaris Finance' },
      { className: 'bg-14', alt: 'Globhe' },
      { className: 'bg-15', alt: 'Super Sourcing' }
    ],
    row_2_images: [
      { className: 'bg-16', alt: 'Yes Referral' },
      { className: 'bg-17', alt: 'RTUData' },
      { className: 'bg-18', alt: 'Braided Data Solution' }
    ]
  },
  {
    id: 3,
    row_1_images: [
      { className: 'bg-19', alt: 'Paravision' },
      { className: 'bg-20', alt: 'Little Darlings Nursery' },
      { className: 'bg-21', alt: 'Sumtracker' }
    ],
    row_2_images: [
      { className: 'bg-22', alt: 'Givelify' },
      { className: 'bg-23', alt: 'Risk Fintech' },
      { className: 'bg-24', alt: 'We Team' }
    ]
  },
  {
    id: 4,
    row_1_images: [
      { className: 'bg-25', alt: 'Wetales' },
      { className: 'bg-26', alt: 'Doqfy' },
      { className: 'bg-27', alt: 'Sanskrit CR' }
    ],
    row_2_images: [
      { className: 'bg-28', alt: 'Secure Slice' },
      { className: 'bg-29', alt: 'Neurog' },
      { className: 'bg-30', alt: 'Victor' }
    ]
  },
  {
    id: 5,
    row_1_images: [
      { className: 'bg-31', alt: 'Alma Systems' },
      { className: 'bg-32', alt: 'Maroon Migrates' },
      { className: 'bg-33', alt: 'Valunova' }
    ],
    row_2_images: [
      { className: 'bg-34', alt: 'Sourcebae' },
      { className: 'bg-35', alt: 'Rocketreach' },
      { className: 'bg-36', alt: 'Rugg Media Group' }
    ]
  },
  {
    id: 6,
    row_1_images: [
      { className: 'bg-37', alt: 'Market Place' },
      { className: 'bg-38', alt: 'Optimize Technologies' },
      { className: 'bg-39', alt: 'Raps Media Outdoor Media' }
    ],
    row_2_images: [
      { className: 'bg-40', alt: 'Mind It Systems' }
    ]
  }
];

export const clienteleMobileData = [
  {
    id: 0,
    row_1_images: [
      { className: 'bg-1', alt: 'Johnson & Johnson' },
      { className: 'bg-2', alt: 'Bakeri' }
    ],
    row_2_images: [
      { className: 'bg-3', alt: 'Symphony' },
      { className: 'bg-4', alt: 'Pareit' }
    ]
  },
  {
    id: 1,
    row_1_images: [
      { className: 'bg-5', alt: 'Gwrs' },
      { className: 'bg-6', alt: 'UP Government' }
    ],
    row_2_images: [
      { className: 'bg-7', alt: 'Gujarat State Yog Board' },
      { className: 'bg-8', alt: 'Gmr' }
    ]
  },
  {
    id: 2,
    row_1_images: [
      { className: 'bg-9', alt: 'Algo' },
      { className: 'bg-10', alt: 'IIFL Ahmisa Run' }
    ],
    row_2_images: [
      { className: 'bg-11', alt: 'Government of Assam' },
      { className: 'bg-12', alt: 'Capital Numbers' }
    ]
  },
  {
    id: 3,
    row_1_images: [
      { className: 'bg-13', alt: 'Solaris Finance' },
      { className: 'bg-14', alt: 'Globhe' }
    ],
    row_2_images: [
      { className: 'bg-15', alt: 'Super Sourcing' },
      { className: 'bg-16', alt: 'Yes Referral' }
    ]
  },
  {
    id: 4,
    row_1_images: [
      { className: 'bg-17', alt: 'RTUData' },
      { className: 'bg-18', alt: 'Braided Data Solution' }
    ],
    row_2_images: [
      { className: 'bg-19', alt: 'Paravision' },
      { className: 'bg-20', alt: 'Little Darlings Nursery' }
    ]
  },
  {
    id: 5,
    row_1_images: [
      { className: 'bg-21', alt: 'Sumtracker' },
      { className: 'bg-22', alt: 'Givelify' }
    ],
    row_2_images: [
      { className: 'bg-23', alt: 'Risk Fintech' },
      { className: 'bg-24', alt: 'We Team' }
    ]
  },
  {
    id: 6,
    row_1_images: [
      { className: 'bg-25', alt: 'Wetales' },
      { className: 'bg-26', alt: 'Doqfy' }
    ],
    row_2_images: [
      { className: 'bg-27', alt: 'Sanskrit CR' },
      { className: 'bg-28', alt: 'Secure Slice' }
    ]
  },
  {
    id: 7,
    row_1_images: [
      { className: 'bg-29', alt: 'Neurog' },
      { className: 'bg-30', alt: 'Victor' }
    ],
    row_2_images: [
      { className: 'bg-31', alt: 'Alma Systems' },
      { className: 'bg-32', alt: 'Maroon Migrates' }
    ]
  },
  {
    id: 8,
    row_1_images: [
      { className: 'bg-33', alt: 'Valunova' },
      { className: 'bg-34', alt: 'Sourcebae' }
    ],
    row_2_images: [
      { className: 'bg-35', alt: 'Rocketreach' },
      { className: 'bg-36', alt: 'Rugg Media Group' }
    ]
  },
  {
    id: 9,
    row_1_images: [
      { className: 'bg-37', alt: 'Market Place' },
      { className: 'bg-38', alt: 'Optimize Technologies' }
    ],
    row_2_images: [
      { className: 'bg-39', alt: 'Raps Media Outdoor Media' },
      { className: 'bg-40', alt: 'Mind It Systems' }
    ]
  }
];

export const testmonialImageUrl = "/images/testimonial/testimonial_sprite.webp"

export const testimonialData = [
  {
    quote: "testimonial.quote1",
    text: "testimonial.text1",
    logo: "bg-1",
    location: "testimonial.location1",
    industry: "testimonial.industry1",
    alt:"PareIT"
  },
  {
    quote: "testimonial.quote2",
    text: "testimonial.text2",
    logo: "bg-2",
    location: "testimonial.location2",
    industry: "testimonial.industry2",
    alt:"Action Toy Rental"
  },
  {
    quote: "testimonial.quote3",
    text: "testimonial.text3",
    logo: "bg-3",
    location: "testimonial.location3",
    industry: "testimonial.industry3",
    alt:"Dogan.AI"
  },
  {
    quote: "testimonial.quote4",
    text: "testimonial.text4",
    logo: "bg-4",
    location: "testimonial.location4",
    industry: "testimonial.industry4",
    alt:"Nok Advertising"
  },
  {
    quote: "testimonial.quote5",
    text: "testimonial.text5",
    logo: "bg-5",
    location: "testimonial.location5",
    industry: "testimonial.industry5",
    alt:"Paras Interiors"
  },
  {
    quote: "testimonial.quote6",
    text: "testimonial.text6",
    logo: "bg-6",
    location: "testimonial.location6",
    industry: "testimonial.industry6",
    alt:"Raps Mobile Outdoor Media"
  },
  {
    quote: "testimonial.quote7",
    text: "testimonial.text7",
    logo: "bg-7",
    location: "testimonial.location7",
    industry: "testimonial.industry7",
    alt:"Solaris Finance"
  },
  {
    quote: "testimonial.quote8",
    text: "testimonial.text8",
    logo: "bg-8",
    location: "testimonial.location8",
    industry: "testimonial.industry8",
    alt:"Tailors Point"
  },
  {
    quote: "testimonial.quote9",
    text: "testimonial.text9",
    logo: "bg-9",
    location: "testimonial.location9",
    industry: "testimonial.industry9",
    alt:"VVictor Technologies"
  },
  {
    quote: "testimonial.quote10",
    text: "testimonial.text10",
    logo: "bg-10",
    location: "testimonial.location10",
    industry: "testimonial.industry10",
    alt:"Valunova"
  },
  {
    quote: "testimonial.quote11",
    text: "testimonial.text11",
    logo: "bg-11",
    location: "testimonial.location11",
    industry: "testimonial.industry11",
    alt:"WeTales.in"
  },
  {
    quote: "testimonial.quote12",
    text: "testimonial.text12",
    logo: "bg-12",
    location: "testimonial.location12",
    industry: "testimonial.industry12",
    alt:"WeTeams"
  },
  {
    quote: "testimonial.quote13",
    text: "testimonial.text13",
    logo: "bg-13",
    location: "testimonial.location13",
    industry: "testimonial.industry13",
    alt:"Supersourcing"
  },
  {
    quote: "testimonial.quote14",
    text: "testimonial.text14",
    logo: "bg-14",
    location: "testimonial.location14",
    industry: "testimonial.industry14",
    alt:"Sumtracker"
  },
  {
    quote: "testimonial.quote15",
    text: "testimonial.text15",
    logo: "bg-15",
    location: "testimonial.location15",
    industry: "testimonial.industry15",
    alt:"Bakeri"
  },
];

export const gwrImageUrl = "/images/gwrs/gwrs_sprite.webp"

export const achivementsData = [
  {
    text: "achivements.text1",
    btext: "achivements.btext1",
    img: "bg-1",
    alt:"Govt of UP"
  },
  {
    text: "achivements.text2",
    btext: "achivements.btext2",
    img: "bg-2",
    alt:"Gujarat State Yog Board"
  },
  {
    text: "achivements.text3",
    btext: "achivements.btext3",
    img: "bg-3",
    alt:"Ahimsa Run"
  },
  {
    text: "achivements.text4",
    btext: "achivements.btext4",
    img: "bg-4",
    alt:"Globhe Drones AB"
  },
  {
    text: "achivements.text5",
    btext: "achivements.btext5",
    img: "bg-5",
    alt:"Little Darlings Nursery"
  },
  {
    text: "achivements.text6",
    btext: "achivements.btext6",
    img: "bg-6",
    alt:"Assam Govt"
  },
  {
    text: "achivements.text7",
    btext: "achivements.btext7",
    img: "bg-6",
    alt:"Assam Govt"
  },
];
export const BusinessVerticalsCards = [
  {
    title: "industries.title1",
    description: "industries.description1",
    image: "../images/business-verticals/hc.gif",
  },
  {
    title: "industries.title2",
    description: "industries.description2",
    image: "../images/business-verticals/re.gif",
  },
  {
    title: "industries.title3",
    description: "industries.description3",
    image: "../images/business-verticals/Game.gif",
  },
  {
    title: "industries.title4",
    description: "industries.description4",
    image: "../images/business-verticals/ft.gif",
  },
  {
    title: "industries.title5",
    description: "industries.description5",
    image: "../images/business-verticals/law.gif",
  },
  {
    title: "industries.title6",
    description: "industries.description6",
    image: "../images/business-verticals/sm.gif",
  },
  {
    title: "industries.title7",
    description: "industries.description7",
    image: "../images/business-verticals/cs.gif",
  },
  {
    title: "industries.title8",
    description: "industries.description8",
    image: "../images/business-verticals/cd.gif",
  },
];
export const BusinessVerticalsTabletCards = [
  {
    row_1_images: [
      {
        title: "industries.title1",
        description: "industries.description1",
        image: "../images/business-verticals/Frame_1000002780.webp",
      },
      {
        title: "industries.title2",
        description: "industries.description2",
        image: "../images/business-verticals/Frame_1000002780-1.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title3",
        description: "industries.description3",
        image: "../images/business-verticals/Frame_1000002780-2.webp",
      },
      {
        title: "industries.title4",
        description: "industries.description4",
        image: "../images/business-verticals/Frame_1000002780-3.webp",
      },
    ],
  },
  {
    row_1_images: [
      {
        title: "industries.title5",
        description: "industries.description5",
        image: "../images/business-verticals/Frame_1000002780-4.webp",
      },
      {
        title: "industries.title6",
        description: "industries.description6",
        image: "../images/business-verticals/Frame_1000002780-5.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title7",
        description: "industries.description7",
        image: "../images/business-verticals/Frame_1000002780-6.webp",
      },
      {
        title: "industries.title8",
        description: "industries.description8",
        image: "../images/business-verticals/Frame_1000002780-7.webp",
      },
    ],
  },
];

export const areaExpertiseImageUrl = "/images/area-experties/area_sprite.webp"

export const AreaExperticsData = [
  {
    id: 1,
    title: "areaexpertise.AI",
    cards: [
      {
        title: "areaexpertise.ai1",
        image: "bg-1",
      },
      {
        title: "areaexpertise.ai2",
        image: "bg-2",
      },
      {
        title: "areaexpertise.ai3",
        image: "bg-3",
      },
      {
        title: "areaexpertise.ai4",
        image: "bg-4",
      },
      {
        title: "areaexpertise.ai5",
        image: "bg-5",
      },
      {
        title: "areaexpertise.ai6",
        image: "bg-6",
      },
    ],
  },
  {
    id: 2,
    title: "areaexpertise.GenAI",
    cards: [
      {
        title: "areaexpertise.genai1",
        image: "bg-7",
      },
      {
        title: "areaexpertise.genai2",
        image: "bg-8",
      },
      {
        title: "areaexpertise.genai3",
        image: "bg-9",
      },
      {
        title: "areaexpertise.genai4",
        image: "bg-10",
      },
      {
        title: "areaexpertise.genai5",
        image: "bg-11",
      },
      {
        title: "areaexpertise.genai6",
        image: "bg-12",
      },
    ],
  },
  {
    id: 3,
    title: "areaexpertise.Development",
    cards: [
      {
        title: "areaexpertise.dev1",
        image: "bg-13",
      },
      {
        title: "areaexpertise.dev2",
        image: "bg-14",
      },
      {
        title: "areaexpertise.dev3",
        image: "bg-15",
      },
      {
        title: "areaexpertise.dev4",
        image: "bg-16",
      },
      {
        title: "areaexpertise.dev5",
        image: "bg-17",
      },
      {
        title: "areaexpertise.dev6",
        image: "bg-18",
      },
    ],
  },
  {
    id: 4,
    title: "areaexpertise.Deployment",
    cards: [
      {
        title: "areaexpertise.dep1",
        image: "bg-19",
      },
      {
        title: "areaexpertise.dep2",
        image: "bg-20",
      },
      {
        title: "areaexpertise.dep3",
        image: "bg-21",
      },
      {
        title: "areaexpertise.dep4",
        image: "bg-22",
      },
      {
        title: "areaexpertise.dep5",
        image: "bg-23",
      },
      {
        title: "areaexpertise.dep6",
        image: "bg-24",
      },
    ],
  },
];

export const productsData = [
  {
    id: 1,
    title: "products.product1_title",
    sub_title: "products.product1_subtitle",
    description_1: "products.product1_desc1",
    description_2: "products.product1_desc2",
    product_image: "../images/products/Haveto_Logo.webp",
    explore_url: "https://haveto.com/",
    link_id: "haveto",
  },
  {
    id: 2,
    title: "products.product3_title",
    sub_title: "products.product3_subtitle",
    description_1: "products.product3_desc1",
    description_2: "products.product3_desc2",
    product_image: "../images/products/FxchatLogo.webp",
    explore_url: "https://fxchat.htree.plus/",
    link_id: "fxchat",
  },
  {
    id: 3,
    title: "products.product2_title",
    sub_title: "products.product2_subtitle",
    description_1: "products.product2_desc1",
    description_2: "products.product2_desc2",
    product_image: "../images/products/AI_Coder.webp",
    link_id: "aicoder",
  },
  {
    id: 4,
    title: "products.product4_title",
    sub_title: "products.product4_subtitle",
    description_1: "products.product4_desc1",
    description_2: "products.product4_desc2",
    product_image: "../images/products/AITutor.webp",
    explore_url: "https://aitutor.htree.plus/",
    link_id: "aitutor",
  },
];
export const servicesData = [
  {
    id: 0,
    cardTitle: "services.card1",
    cardDesc: "services.desc1",
    // modalTitleId: "project-outsourcing-modal-title-vcenter",
    // modalTitle: "projectoutsourcing.title",
    // modalComponent: <ProjectOutsourcing />,
  },
  {
    id: 1,
    cardTitle: "services.card2",
    cardDesc: "services.desc2",
    // modalTitleId: "on-demand-developer-modal-title-vcenter",
    // modalTitle: "ondemanddev.title",
    // modalComponent: <OnDemandDe />,
  },
  {
    id: 2,
    cardTitle: "services.card3",
    cardDesc: "services.desc3",
    // modalTitleId: "tech-and-talent-modal-title-vcenter",
    // modalTitle: "techandtalent.title",
    // modalComponent: <TechandTalent />,
  },
  {
    id: 3,
    cardTitle: "services.card4",
    cardDesc: "services.desc4",
    // modalTitleId: "tech-and-talent-modal-title-vcenter",
    // modalTitle: "techandtalent.title",
    // modalComponent: <TechandTalent />,
  },
];

export const successimage = {
  image: "../images/success_image.webp",
};

export const navbarheaderlinks = [
  {
    id: 0,
    title: "navbarlinks.title1",
    links: [
      {
        link_title: "navbarlinks.title1",
        link_id: "experience",
      },
      {
        link_title: "navbarlinks.title1_link1",
        link_id: "trusted-by-leading-brands",
      },
      {
        link_title: "navbarlinks.title1_link2",
        link_id: "hire-ex-experts",
      },
      {
        link_title: "navbarlinks.title1_link3",
        link_id: "hear-from-our-clients",
      },
    ],
  },
  {
    id: 1,
    title: "navbarlinks.title2",
    links: [
      {
        link_title: "navbarlinks.title2",
        link_id: "skills",
      },
      {
        link_title: "navbarlinks.title2_link1",
        link_id: "techstack",
      },
      {
        link_title: "navbarlinks.title2_link2",
        link_id: "areaexpertise",
      },
    ],
  },
  {
    id: 2,
    title: "navbarlinks.title3",
    links: [
      {
        link_title: "navbarlinks.title3",
        link_id: "world-records",
      },
      {
        link_title: "navbarlinks.title3_link2",
        link_id: "awards-and-recognition",
      },
      {
        link_title: "navbarlinks.title3_link1",
        link_id: "guinness-world-records",
      },
      // {
      //   link_title: "navbarlinks.title3_link2",
      //   link_id: "awards",
      // },
    ],
  },
  {
    id: 3,
    title: "navbarlinks.title4",
    links: [
      {
        link_title: "navbarlinks.title4",
        link_id: "services",
      },
      {
        link_title: "navbarlinks.title4_link1",
        link_id: "services-we-offer",
      },
      {
        link_title: "navbarlinks.title4_link2",
        link_id: "industry-verticals",
      },
    ],
  },
  {
    id: 4,
    title: "navbarlinks.title5",
    links: [
      {
        link_title: "navbarlinks.title5",
        link_id: "products",
      },
      {
        link_title: "navbarlinks.title5_link1",
        link_id: "haveto",
      },
      {
        link_title: "navbarlinks.title5_link2",
        link_id: "fxchat",
      },
      {
        link_title: "navbarlinks.title5_link3",
        link_id: "aicoder",
      },
      {
        link_title: "navbarlinks.title5_link4",
        link_id: "aitutor",
      },
    ],
  },
  {
    id: 5,
    title: "navbarlinks.title9",
    links: [
      {
        link_title: "navbarlinks.title9",
        link_id: "aboutus",
      },
      {
        link_title: "navbarlinks.title9_link1",
        link_id: "ourculture",
      },
      {
        link_title: "navbarlinks.title9_link2",
        link_id: "ourfounders",
      },
    ],
  },
  {
    id: 6,
    title: "navbarlinks.title7",
    links: [
      {
        link_title: "navbarlinks.title7_link1",
        link_id: "https://fxis.ai/edu/category/trends/",
      },
      {
        link_title: "navbarlinks.title7_link2",
        link_id: "https://fxis.ai/edu/category/educational/",
      },
      {
        link_title: "navbarlinks.title7_link3",
        link_id: "https://fxis.ai/edu/category/programming/",
      },
      {
        link_title: "navbarlinks.title7_link4",
        link_id: "https://fxis.ai/edu/category/data-science/",
      },
      {
        link_title: "navbarlinks.title7_link5",
        link_id: "https://fxis.ai/edu/category/blockchain/",
      },
    ],
    
  },
  // {
  //   id: 7,
  //   title: "navbarlinks.title8",
  //   title_link: "contact",
  // },
];

export const OurFoundersData = [
  {
    image: "../images/OurFounders/founder1.webp",
    desc: "ourfounders.desc1",
    name: "ourfounders.name1",
    info: "ourfounders.info1",
    social_media: [
      { icon: "../images/OurFounders/linkedin.webp", link: "https://www.linkedin.com/in/hemenashodia/" },
      // { icon: "../images/OurFounders/instagram.webp", link: "https://www.instagram.com/he_men/?igsh=dmp2enpyeWxvbzVi" }
    ],
    meeting_link: "https://calendly.com/hemenashodia/30min", 
    open_quote: "../images/OurFounders/open_quote.webp",
    close_quote: "../images/OurFounders/close_quote.webp"
  },
  {
    image: "../images/OurFounders/founder2.webp",
    desc: "ourfounders.desc2",
    name: "ourfounders.name2",
    info: "ourfounders.info2",
    social_media: [
      { icon: "../images/OurFounders/linkedin.webp", link: "https://www.linkedin.com/in/pavan-b-3153971a6/" },
      { icon: "../images/OurFounders/bakery.webp", link: "https://bakeri.com/" }
    ],
    open_quote: "../images/OurFounders/open_quote.webp",
    close_quote: "../images/OurFounders/close_quote.webp"
  },
];

export const homePaths = [
  "/", "/trusted-by-leading-brands", "/hire-ex-experts", "/hire-ai-experts", "/hear-from-our-clients", 
  "/techstack", "/areaexpertise", "/guinness-world-records", "/awards-and-recognition", 
  "/services-we-offer", "/industry-verticals", "/haveto", "/fxchat", 
  "/aicoder", "/aitutor", "/contact", "/ourfounders", "/ourculture","/experience","/skills","/world-records","/services","/products","/aboutus"
];