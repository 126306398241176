import React from "react";
import { OurFoundersData } from "../../../utils/constants";
import "./OurFounders.css";
import { useTranslation } from "react-i18next";
import LazyImage from "../LazyImage"
import { useCalendlyEventListener, PopupButton } from "react-calendly";

const FounderCard = ({ founder, index }) => {
  const { t } = useTranslation();

  return (
    <div className={`founders-container ${index % 2 === 0 ? 'row' : 'row-reverse'}`}>
      <div className="founder-image-container">
        <img
          src={founder.image}
          alt={t(founder.name)}
          className="founder-image"
        />
      </div>
      <div className="founder-info">
        <div className="desc-box">
          <div id="quotebox" className="quotebox1">
            <LazyImage src={founder.open_quote} alt="" className="quote1" />
          </div>
          <h3 className="founder-description">{t(founder.desc)}</h3>
          <div id="quotebox" className="quotebox2">
            <LazyImage src={founder.close_quote} alt="" className="quote2" />
          </div>
        </div>
        <div className="founder-info-box">
          <h2 className="founder-name">{t(founder.name)}</h2>
          <span className="founder-title">{t(founder.info)}</span>
          <div className="founder-social-media-icon">
            {founder.social_media.map((social, socialIndex) => (
              <a key={socialIndex} href={social.link} target="_blank" rel="noopener noreferrer">
                <LazyImage src={social.icon} alt={`Social Media ${socialIndex + 1}`} className="social2-icon" />
              </a>
            ))}
             {/* Add PopupButton only for the first founder */}
             {index === 0 && (
              <PopupButton
                url={founder.meeting_link} // Dynamic link for the first founder
                className="schedule-meeting-button"
                rootElement={document.getElementById("root")}
                text="Schedule a Meet"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OurFounders = () => {
  const { t } = useTranslation();

  return (
    <section id="ourfounders" className="bg-light">
      <div className="container-fluid fadeIn text-center">
        <div className="row wow fadeIn">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h2 className="gradient-text-default">{t('ourfounders.title')}</h2>
            </div>
          </div>
        </div>
        <div className="founders-wrapper">
          {OurFoundersData.map((founder, index) => (
            <FounderCard key={index} founder={founder} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurFounders;