import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Products.css";
import { productsData } from "../../../utils/constants";
import ProductModal from "./ProductModal";


const Products = ({ mounted }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1); // Remove leading "/"
    
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Small delay to ensure elements are rendered
  }, [location]);

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <section  >
        <div className="products-container">
          <div className="row wow fadeIn">
            <div className="col-md-12 text-center">
              <div className="title d-inline-block">
                <h2 className="gradient-text-default">{t("products.title")}</h2>
              </div>              
            </div>
          </div>
          <div className="products-grid">
            {productsData.map((product, index) => (
              <div
                key={index}
                id={product.link_id}
                className={`product-item ${
                  product.id % 2 === 0 ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <div
                  className="product-image-wrapper"
                  style={{
                    background:
                      product.id % 2 === 0
                        ? "linear-gradient(270deg, #F4EFFF 0%, #FFFFFF 100%)"
                        : "linear-gradient(90deg, #F4EFFF 0%, #FFFFFF 100%)",
                  }}
                >
                  <div
                    className={`product-image text-right wow`}
                  >
                    <img
                      src={product.product_image}
                      className="lazy"
                      width="80%"
                      height="auto"
                      alt="HaveTo"
                    />
                  </div>
                </div>
                <div
                  className={` wow product-content `}
                >
                  <div className="product-text">
                    <h2 className="product-title">{t(product.title)}</h2>
                    <h5>{t(product.sub_title)}</h5>
                    <div className="product-desc">
                      <p className="">{t(product.description_1)}</p>
                      <p className="">{t(product.description_2)}</p>
                    </div>
                    {product.explore_url ? (
                      <a
                        href={product.explore_url}
                        target="_blank"
                        className="btn btn-large productBtn btn-rounded p-2"
                        rel="noreferrer"
                      >
                        {t("products.exploremore")}
                      </a>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-large productBtn btn-rounded p-2"
                        onClick={() => {
                          handleModalOpen();
                        }}
                      >
                        {t("products.exploremore")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {showModal && (
        <ProductModal
          showModal={showModal}
          handleModalClose={handleModalClose}
        />
      )}
    </React.Fragment>
  );
};

export default Products;
