import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import OurCulture from "../OurCultureSection/OurCulture";
import OurFounders from "../OurFounderSection/OurFounders";

const Our = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1); // Remove leading "/"

    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Small delay to ensure elements are rendered
  }, [location]);

  return (
    <div id="aboutus" className="category-page">
      <OurCulture id="ourculture" />
      <OurFounders id="ourfounders" />
    </div>
  );
};

export default Our;
