import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import HireAIExpertModal from "../Modals/HireAIExpertModal/HireAIExpertModal";
import SucceesModal from "../whatsappBot/SucceesModal";
import ApplyForJobModal from "../Modals/ApplyForJobModal";
import ReactGA from "react-ga4";
import axios from "axios";

const Info = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUsingEmailForm, setIsUsingEmailForm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formStatus, setFormStatus] = useState({
    isLoading: false,
    isSuccess: false,
    error: null,
  });
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  
  const getDefaultZoomLevel = () => (window.innerWidth <= 767 ? 1 : 2);

  const [zoomLevel, setZoomLevel] = useState(getDefaultZoomLevel());
  const [iframeKey, setIframeKey] = useState(Date.now());

  const updateZoomLevel = () => {
    setZoomLevel(getDefaultZoomLevel());
    setIframeKey(Date.now());
  };

  const resetZoom = () => {
    setZoomLevel(getDefaultZoomLevel());
    setIframeKey(Date.now());
  };

  useEffect(() => {
    window.addEventListener("resize", updateZoomLevel);
    return () => {
      window.removeEventListener("resize", updateZoomLevel);
    };
  }, []);

  const handleSuccess = () => {
    setIsSuccessModalVisible(true);
  };
  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const resetForm = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setIsUsingEmailForm(false);
    setPhoneNumber("");
    setEmail("");
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const submitWhatsappMessage = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        ...data,
        category: currentCategory,
      });
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  const submitEmailForm = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, category: currentCategory },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  useEffect(() => {
    if (formStatus.error) {
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);

  const iframeSrc = `https://www.google.com/maps/d/embed?mid=1qVPcFGQ-6MqX6lSi1guZthWXLIvR_ME&hl=en&ehbc=2E312F&z=${zoomLevel}`;

  return (
    <section id="contact" className="p-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="address-box title mb-0">
              <div className="bg-overlay gradient-bg1 opacity-8" />
              <div className="address-text text-center text-white position-relative">
                <h2 className="mb-2">fxis.ai</h2>
                <h5 className="mb-4">{t("info.tagline")}</h5>
                <div className="d-flex justify-content-center mt-5 px-4 address-location-container">
                  <div
                    style={{ width: "35%" }}
                    className="d-flex align-items-center address-location-elm"
                  >
                    <div>
                      <FaLocationDot />
                    </div>
                    <p
                      style={{
                        textAlign: "left",
                        margin: "0",
                        marginLeft: "30px",
                      }}
                      className=" mb-0 text-white info-add"
                    >
                      {t("info.usadd1")}
                      {t("info.usadd2")}
                    </p>
                  </div>
                  <div className="mx-5 address-location-mid-elm"></div>
                  <div
                    style={{ width: "40%" }}
                    className="d-flex align-items-center address-location-elm"
                  >
                    <div>
                      <FaLocationDot />
                    </div>
                    <p
                      style={{
                        textAlign: "left",
                        margin: 0,
                        marginLeft: "30px",
                      }}
                      className="mb-0 text-white info-add"
                    >
                      {t("info.indadd1")}
                      {t("info.indadd2")}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-center mt-5 address-inquiry-container"
                  style={{ gap: "40px", margin: "8px" }}
                >
                  <div className="mb-3 d-flex flex-column justify-content-start align-items-start">
                    <p className="m-0 text-white inquiry">
                      {t("info.business")}
                    </p>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <p
                        style={{ margin: 0, marginLeft: "1rem" }}
                        className="text-white mb-0 inquiry"
                      >
                        contact@fxis.ai
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-phone-square" aria-hidden="true" />
                      <p
                        style={{ margin: 0, marginLeft: "1rem" }}
                        className="text-white mb-0 inquiry"
                      >
                        (+91) 97279 99595
                      </p>
                    </div>
                    <button
                      style={{
                        marginTop: "1rem",
                        backgroundColor: "#fff",
                        color: "#5928d1",
                        border: "1px solid #ccc",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.history.pushState({}, "", "/hire-ai-experts");
                        ReactGA.event({
                          category: "hire_ai_exports_hero_click",
                          action: "hire_ai_exports_hero_click",
                          label: "hire_ai_exports_hero_click",
                          value: 1,
                        });
                        setIsModalVisible(true);
                        setCurrentCategory("collaborate");
                      }}
                    >
                      Business Inquiry
                    </button>
                  </div>
                  <div className="vr"></div>
                  <div className="mb-3 d-flex flex-column justify-content-start align-items-start">
                    <p className="m-0 text-white inquiry">{t("info.career")}</p>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <p
                        style={{ margin: 0, marginLeft: "1rem" }}
                        className="text-white mb-0 inquiry"
                      >
                        career@fxis.ai
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-phone-square" aria-hidden="true" />
                      <p
                        style={{ margin: 0, marginLeft: "1rem" }}
                        className="text-white mb-0 inquiry"
                      >
                        (+91) 98987 49300
                      </p>
                    </div>
                    <button
                     className="contact-buttons"
                      style={{
                        
                        marginTop: "1rem",
                        backgroundColor: "#fff",
                        color: "#5928d1",
                        border: "1px solid #ccc",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        ReactGA.event({
                          category: "apply_for_job_hero_click",
                          action: "apply_for_job_hero_click",
                          label: "apply_for_job_hero_click",
                          value: 1,
                        });
                        setIsModalVisible(true);
                        setCurrentCategory("career");
                      }}
                    >
                      Career Inquiry
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <p
                    style={{ margin: 0, marginLeft: "1rem" }}
                    className="text-white mb-0 inquiry"
                  >
                    {t("info.timing")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div id="map" className="half-map">
              <div
                style={{ width: "100%", height: "110%", overflow: "hidden" }}
                className="map-wrap"
              >
                <iframe
                  key={iframeKey}
                  title="map"
                  src={iframeSrc}
                  width="100%"
                  height="103%"
                  frameBorder="0"
                  style={{ border: "none", marginTop: -80 }}
                  loading="lazy"
                ></iframe>
              </div>
              <button
                onClick={resetZoom}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: 1,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                Reset Zoom
              </button>
            </div>
          </div>
        </div>
        {currentCategory === "career" ? (
          <ApplyForJobModal
            isModalVisible={isModalVisible}
            resetForm={resetForm}
            formStatus={formStatus}
            isUsingEmailForm={isUsingEmailForm}
            errorMessage={errorMessage}
            setIsUsingEmailForm={setIsUsingEmailForm}
            setErrorMessage={setErrorMessage}
            handleEmailSubmission={submitEmailForm}
            handleWhatsappSubmission={submitWhatsappMessage}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        ) : (
          <HireAIExpertModal
            isModalVisible={isModalVisible}
            resetForm={resetForm}
            formStatus={formStatus}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleEmailSubmission={submitEmailForm}
            handleWhatsappSubmission={submitWhatsappMessage}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        )}
        {isSuccessModalVisible && (
          <SucceesModal
            SuccessModalVisible={isSuccessModalVisible}
            onclose={handleCloseSuccessModal}
          />
        )}
      </div>
    </section>
  );
};

export default Info;
