import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import "./NavBarLinks.css";
import { navbarheaderlinks } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import ClientRegistration from "../../Auth/ClientRegistration/ClientRegistration";

function NavBarLinks({ isSideBar }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const toggleDropdown = (id) => {
    setActiveDropdown((prevState) => (prevState === id ? null : id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScrollOnLoad = () => {
      const pathname = location.pathname;
      const sectionId = pathname.substring(1); // Remove leading "/"

      if (sectionId) {
        const section = document.getElementById(sectionId);

        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    window.addEventListener("load", handleScrollOnLoad);

    return () => {
      window.removeEventListener("load", handleScrollOnLoad);
    };
  }, [location]);

  const handleClick = (id) => {
    if (id) {
      if (id.startsWith("https://")) {
        window.open(id, "_blank", "noopener,noreferrer");
        const clean_id = id.replace(/[^a-zA-Z0-9]/g, '_');
        ReactGA.event({
          category: "nav_go_to_" + clean_id,
          action: "nav_go_to_" + clean_id,
          label: "nav_go_to_" + clean_id,
          value: 1,
        });
      } else {
        const section = document.getElementById(id);
        const clean_id = id.replace(/[^a-zA-Z0-9]/g, '_');
        ReactGA.event({
          category: "nav_click_to_" + clean_id,
          action: "nav_click_to_" + clean_id,
          label: "nav_click_to_" + clean_id,
          value: 1,
        });
        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
          document.getElementById("btn_sideNavClose").click();
          history.push(`${id}`);
        } 
        if (id =='hire-ai-experts') {
          history.push(`${id}`);
        }
      }
    }
  };

  return !isSideBar ? (
    <div
      className="d-flex align-items-center navbar-new-link"
      ref={dropdownRef}
    >
      {navbarheaderlinks.map((dropdown) => (
        <div className="dropdown" key={dropdown.id}>
          {dropdown.title_link ? (
            <a
              className="link scroll"
              href={dropdown.title_link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleClick(dropdown.title_link);
              }}
            >
              {t(dropdown.title)}
            </a>
          ) : (
            <div
              className="link scroll"
              role="button"
              id={`${dropdown.title}Dropdown`}
              aria-haspopup="true"
              aria-expanded={activeDropdown === dropdown.id}
              onClick={() => toggleDropdown(dropdown.id)}
              style={{ cursor: "pointer" }}
            >
              {t(dropdown.title)}
              {dropdown.links && (
                <span>
                  <IoMdArrowDropdown
                    className={`rotate-icon ${
                      activeDropdown === dropdown.id ? "rotate" : ""
                    }`}
                    style={{ fontSize: "20px", verticalAlign: "middle" }}
                  />
                </span>
              )}
            </div>
          )}
          {dropdown.links && (
            <div
              className={`dropdown-menu dropdown-menu-right ${
                activeDropdown === dropdown.id ? "show" : ""
              }`}
              aria-labelledby={`${dropdown.title}Dropdown`}
            >
              {dropdown.links.map((link, idx) =>
                link.link_id.startsWith("https://") ? (
                  <a
                    className="dropdown-item"
                    key={idx}
                    onClick={() => {
                      handleClick(link.link_id);
                      setActiveDropdown(null);
                    }}
                  >
                    {t(link.link_title)}
                  </a>
                ) : (
                  <Link
                    className="dropdown-item"
                    to={`${link.link_id}`}
                    key={idx}
                    onClick={() => {
                      handleClick(link.link_id);
                      setActiveDropdown(null);
                    }}
                  >
                    {t(link.link_title)}
                  </Link>
                )
              )}
            </div>
          )}
        </div>
      ))}
      {/* <ClientRegistration /> */}
    </div>
  ) : (
    <div className="accordion navbar-mob-new-link" id="navbarAccordion">
      {navbarheaderlinks.map((dropdown) => (
        <div className="accordion-item" key={dropdown.id}>
          {dropdown.title_link ? (
            <a
              className="link scroll nav-link"
              href={dropdown.title_link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleClick(dropdown.title_link);
              }}
            >
              {t(dropdown.title)}
            </a>
          ) : (
            <h2 className="accordion-header" id={`heading${dropdown.id}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${dropdown.id}`}
                aria-expanded="false"
                aria-controls={`collapse${dropdown.id}`}
              >
                {t(dropdown.title)}
              </button>
            </h2>
          )}

          {dropdown.links && (
            <div
              id={`collapse${dropdown.id}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${dropdown.id}`}
              data-bs-parent="#navbarAccordion"
            >
              {dropdown.links.map((link, idx) => (
                <div
                  className="accordion-body"
                  onClick={() => handleClick(link.link_id)}
                  style={{ cursor: "pointer" }}
                  key={link.link_id || idx}
                >
                  {link.link_id.startsWith("https://") ? (
                    <a
                      className=""
                      key={idx}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(link.link_id);
                        setActiveDropdown(null);
                      }}
                    >
                      {t(link.link_title)}
                    </a>
                  ) : (
                    <Link
                      className=""
                      to={`${link.link_id}`}
                      key={idx}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(link.link_id);
                        setActiveDropdown(null);
                      }}
                    >
                      {t(link.link_title)}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default NavBarLinks;
