import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LatestArticle.css";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const LatestArticle = () => {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  useEffect(() => {
    const fetchRSSFeed = async () => {
      try {
        const response = await axios.get("https://fxis.ai/edu/feed", {
          headers: {
            "Content-Type": "application/rss+xml",
          },
        });

        const parser = new DOMParser();
        const xml = parser.parseFromString(response.data, "application/xml");
        const items = Array.from(xml.querySelectorAll("item")).slice(0, 4); // Get the latest 3 posts

        const posts = items.map((item) => {
          // Get the description content, which includes the image in HTML format
          const descriptionContent =
            item.querySelector("description").textContent;

          // Parse the description to extract the image URL
          const descriptionDoc = parser.parseFromString(
            descriptionContent,
            "text/html"
          );
          const imageUrl = descriptionDoc.querySelector("img")?.src || ""; // Extract the src attribute of the img tag

          return {
            title: item.querySelector("title").textContent,
            imageUrl: imageUrl, // Correctly set the image URL here
            link: item.querySelector("link").textContent,
            author:
              item.querySelector("creator")?.textContent || "Unknown Author", // Add fallback if no author
            category:
              item.querySelector("category")?.textContent || "Uncategorized", // Add fallback if no category
            pubDate: item.querySelector("pubDate").textContent,
          };
        });

        setArticles(posts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
        setLoading(false);
      }
    };

    fetchRSSFeed();
  }, []);

  const handleMoreArticlesClick = () => {
    window.open("https://fxis.ai/edu/", "_blank");
  };

  if (loading) {
    return (
      <section id="latest-articles">
        <div className="row wow fadeIn">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <Skeleton height={30} width={200} className="mb-4" />
            </div>
          </div>
        </div>
        <div className="latest-articles__container">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="article-card">
              <Skeleton
                height={200}
                width={isMobile && 100}
                className="article-card__image"
              />
              <div className="article-card__content">
                <Skeleton height={20} width={100} className="mb-2" />
                <Skeleton height={25} width={`80%`} className="mb-2" />
                <div className="article-card__date">
                  <Skeleton circle height={20} width={20} className="mr-2" />
                  <Skeleton height={20} width={100} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="button-la">
          <Skeleton
            height={40}
            width={150}
            className="latest-articles__more-button"
          />
        </div>
      </section>
    );
  }

  return (
    articles.length !== 0 && (
      <section id="latest-articles">
        <div className="row wow fadeIn">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h2 className="gradient-text-default">
                {t("latestarticles.title")}
              </h2>
            </div>
          </div>
        </div>
        <div className="latest-articles__container">
          {articles?.map((article,index) => (
            <div
              key={index}
              className="article-card"
              onClick={() => window.open(article.link, "_blank")}
            >
              <img
                src={article.imageUrl}
                alt={article.title}
                className="article-card__image"
              />
              <div className="article-card__content">
                <div className="article-card__category">{article.category}</div>
                <h3 className="article-card__title">{article.title}</h3>
                {/* <p className="article-card__author">By {article?.author}</p> */}
                <p className="article-card__date">
                  <img
                    src="/images/calender.webp"
                    className="calender-image"
                    alt="calendar"
                  />
                  <span>
                    {new Date(article.pubDate).toLocaleDateString("en-US", {
                      month: "long", // Full month name
                      day: "numeric", // Day of the month
                      year: "numeric", // Full year
                    })}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="button-la">
          <button
            onClick={handleMoreArticlesClick}
            className="latest-articles__more-button"
          >
            {t("latestarticles.morearticles")} →
          </button>
        </div>
      </section>
    )
  );
};

export default LatestArticle;
