import React from "react";
import { useTranslation } from "react-i18next";
import { testmonialImageUrl } from "../../../utils/constants";


const TestimonialSprite = ({ className, alt }) => (
  <img className={className} src={testmonialImageUrl} alt={alt} />
);

const TestimonialCard = ({ quote, text, logo, location, industry, alt }) => {
  const { t } = useTranslation();
  return (
    <div className="card testimonial-card">
      <div className="card-body testimonial-card-body">
        <div>
          <h5 className="card-title testimonial-card-title">"{t(quote)}"</h5>
          <p className="card-text testimonial-card-text">{t(text)}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="TestimonialImageContainer">
              <TestimonialSprite className={`${logo} high_res_image`} alt={alt} />
            </div>
          <span
            className="card-text testimonial-card-text"
            style={{ color: "#5928D1",fontWeight: 600  }}
          >
            {t(location)}
          </span>
          <span className="card-text testimonial-card-text">{t(industry)}</span>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
