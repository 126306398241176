import React from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "./CustomCard";
import {
  BusinessVerticalsCards,
} from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";

const BusinessVerticals = ({ slides, isMobile }) => (
  <div className="card-row">
    {slides.map((card, index) => (
      <CustomCard
        key={index}
        title={card.title}
        description={card.description}
        image={card.image}
        isMobile={isMobile}
      />
    ))}
  </div>
);
const Industries = ({ isTablet, isBigLaptop, loading, isMobile }) => {
  const { t } = useTranslation();
  const key = isBigLaptop ? "bigLaptop" : isTablet ? "tablet" : "mobile";

  if (loading) {
    return (
      <div className="row">
        <div className="container-fluid" id="industry">
          <section className="container">
            <div className="row" style={{ marginBottom: "1rem" }}>
              <div className="col-md-12 text-center">
                <Skeleton height={40} width={`30%`} />
              </div>
            </div>
            <div
              className="skeleton-container"
              style={{ width: "100%", display: "flex" }}
            >
              {isBigLaptop && (
                <>
                  <div
                    className="left-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                  <div
                    className="center-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                  <div
                    className="right-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                </>
              )}
              {key === "tablet" && (
                <>
                  <div
                    className="left-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                  <div
                    className="center-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                </>
              )}
              {isMobile && (
                <>
                  <div
                    className="left-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }
  return (
    <div className="row "> {/*className="bg-light"*/}
      <div className="container-fluid" id="industry-verticals">
        <div className="divider"></div>
        <div className="row" id="industries">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h2 className="gradient-text-default">{t("industries.title")}</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row text-center justify-content-center" key={key}>
            <BusinessVerticals slides={BusinessVerticalsCards} isMobile={isMobile} />
          </div>
        </div>

        <div className="divider"></div>
      </div>
    </div>
  );
};
export default Industries;
