import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { servicesData } from "../../../utils/constants";
import HireAIExpertModal from "../../Modals/HireAIExpertModal/HireAIExpertModal";
import SucceesModal from "../../whatsappBot/SucceesModal"; // Ensure this import path is correct
import axios from "axios";
import './Services.css';

const ServiceCard = ({ service, openModal }) => {
  const { t } = useTranslation();

  return (
    <div className="service-card  text-center">
      <h4 className="service-card__title alt-font gradient-text1 d-inline-block">
        {t(service.cardTitle)}
      </h4>
      <div className="service-card__description">
        <p className="service-card__text no-margin">{t(service.cardDesc)}</p>
      </div>
      <div className="service-card__button-wrapper text-center" style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn-large service-card__button btn-rounded  p-2"
          onClick={() => openModal(service)}
        >
          {t("services.hirenow")}
        </button>
      </div>
    </div>
  );
};

const Services = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [formStatus, setFormStatus] = useState({ isLoading: false, isSuccess: false, error: null });
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const { t } = useTranslation();

  const openModal = (service) => {
    setSelectedService(service);
    setIsModalVisible(true);
    const title = service.cardTitle.replace(/[^a-zA-Z0-9]/g, '_');
    ReactGA.event({
      category: "model_" + title,
      action: "model_" + title,
      label: "model_" + title,
      value: 1,
    });
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setPhoneNumber("");
    setEmail("");
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const handleSuccess = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
    // Reset form status if needed
    // setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const submitWhatsappMessage = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        ...data,
        service: t(selectedService.cardTitle),
      });
      setFormStatus({ isLoading: false, isSuccess: true });
      closeModal();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  const submitEmailForm = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, service: t(selectedService.cardTitle) },
        { headers: { "Content-Type": "application/json" } }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      closeModal();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  useEffect(() => {
    if (formStatus.error) {
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);

  return (
    <section id="services-we-offer" > {/*className="bg-light"*/}
    <div className="service-container">
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">{t("services.title")}</h2>
          </div>
        </div>
      </div>
      <div className="row services__content-wrapper">
        <div className="services__cards-container">
          {servicesData.map((service) => (
            <ServiceCard key={service.id} service={service} openModal={openModal} />
          ))}
        </div>
      </div>
      
      {isModalVisible && (
        <HireAIExpertModal
          isModalVisible={isModalVisible}
          resetForm={closeModal}
          formStatus={formStatus}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          title={t(selectedService.cardTitle)}
        />
      )}

      {isSuccessModalVisible && (
        <SucceesModal
          SuccessModalVisible={isSuccessModalVisible}
          onclose={handleCloseSuccessModal} // Use correct handler for closing
        />
      )}
    </div>
    </section>
  );
};

export default Services;
