import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Services from "../ServiceSection/Services";
import Industries from "../ServiceSection/Industries";

const ServicesCategory = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.substring(1); // Remove leading "/"

    setTimeout(() => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Small delay to ensure elements are rendered
  }, [location]);

  return (
    <div id="services" className="category-page">
      <Services id="services-we-offer" />
      <Industries id="industries" />
    </div>
  );
};

export default ServicesCategory;
