import React from "react";
import "./AreaExpertics.css";
import { AreaExperticsData, areaExpertiseImageUrl} from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const AreaSprite = ({ className, alt }) => (
  <img className={className} src={areaExpertiseImageUrl} alt={alt}/>
);

const AreaExpertics = () => {
  const { t } = useTranslation();

  return (
    <section id="areaexpertise">
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">
              {t("areaexpertise.title")}
            </h2>
          </div>
        </div>
      </div>
      <div className="row expertise__content-wrapper">
        <div className="expertise__cards-container">
          {AreaExperticsData.map((category) => (
            <div key={category.id} className="expertise-column">
              <h3 className="expertise-title">{t(category.title)}</h3>
              <div className="expertise-items">
                {category.cards.map((card, index) => (
                  <div key={index} className="expertise-item">
                    <div key={index} className="areaImageContainer">
                      <AreaSprite className={card.image} alt={t(card.title)} />
                    </div>
                    <span className="expertise-name">{t(card.title)}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AreaExpertics;
